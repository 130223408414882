import React, { useRef, useState } from "react";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, Grid,
  InputLabel, MenuItem, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableRow
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { refreshVatPackage, vatPackageState } from "../../../SharedState/VatPackageState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";


function ProductVatOutputAllocation(props) {

  const updatePowdrModelGlobalRef = useRef();

  const [triggerRefresh, setTriggerRefresh] = useState([]);

  // eslint-disable-next-line no-unused-vars

  const { products, vatRates} = props;

  const handleChange = (event) => {

    console.log('ProductVatOutputAllocation')

    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let productId = parseInt(valAndIdArr[1]);

    const product = products.find(product => product.productDto.id === productId);

    // update the project vat output rate
    product.productDto.vatOutputRate = value

    setTriggerRefresh({
      ...triggerRefresh
    })

  };


  return (
    <React.Fragment>
      <DialogContentText>
        Product VAT allocation
      </DialogContentText>


      <TableContainer component={Paper}>
        {products &&
          <Table sx={{ m: 1, width: 300 }} aria-label="simple table" size="small" dense="true">
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.productDto.name + "-expense"}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {product.productDto.name}
                  </TableCell>
                  <TableCell align="right">

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.productDto.vatOutputRate + "-" + product.productDto.id}
                        label="Vat Rate"
                        onChange={handleChange}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + product.productDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>)
}



function ExpenseVatOutputAllocation(props) {

  const updatePowdrModelGlobalRef = useRef();

  const [triggerRefresh, setTriggerRefresh] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { products, expenses, vatRates, calcCurrentLiabilitiesWrapper} = props;

  const handleChangeProducts = (event) => {
    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let productId = parseInt(valAndIdArr[1]);

    const product = products.find(product => product.productDto.id === productId);

    // update the project vat output rate
    product.productDto.vatInputRate = value

    setTriggerRefresh({
      ...triggerRefresh
    })

  };


  const handleChangeExpense = (event) => {

    console.log('handleChangeExpense')

    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let expenseId = parseInt(valAndIdArr[1]);

    const expense = expenses.find(expense => expense.overheadDto.id === expenseId);

    // update the project vat output rate
    expense.overheadDto.vatInputRate = value

    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  return (
    <React.Fragment>
      <DialogContentText>
        Expenses VAT allocation
      </DialogContentText>



      <TableContainer component={Paper}>
        {products &&
          <Table aria-label="simple table" size="small" dense="true">
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.productDto.name + "-expense"}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {product.productDto.name}
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.productDto.vatInputRate + "-" + product.productDto.id}
                        label="Vat Rate"
                        onChange={handleChangeProducts}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + product.productDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        {expenses &&
          <Table aria-label="simple table" size="small" dense="true">
            <TableBody>
              {expenses.map((expense) => (
                <TableRow
                  key={expense.overheadDto.name}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {expense.overheadDto.name}
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={expense.overheadDto.vatInputRate + "-" + expense.overheadDto.id}
                        label="Vat Rate"
                        onChange={handleChangeExpense}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + expense.overheadDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

    </React.Fragment>)
}

function EditCurrentLiabilityVAT(props) {

  const { currentLiabilityNames, currentliability, open, setOpen, products, expenses, vatRates, recalculateCurrentLiabilities, manualRecalculation, calcCurrentLiabilitiesWrapper, updatePowdrModelGlobalRef} = props;

  const setRevenueAndCost = useSetRecoilState(revenueAndCostState);
  const setOverheads = useSetRecoilState(overheadsState);
  const setVatPackageAtom = useSetRecoilState(vatPackageState);
  const currentAssets = useRecoilValue(currentAssetsState);

  const [showSpinner, setShowSpinner] = useState(false);

  const { project } = useProject();

  const validationSchema = Yup.object().shape({
    currentLiabilityName: Yup.string()
      .min(5, "Must be at least 5 characters")
      .max(50, "Must be less than 50 characters")
      .required("Required")
      .notOneOf(currentLiabilityNames, "Must have a unique name"),
  });

  const initialValues = {
    currentLiabilityName: currentliability?.name,
    assumptionFormat: currentliability?.assumption,
    balancePositionFlag: currentliability?.position,
    canDelete: currentliability?.canDelete,
  };


  function subVatChanges() {
    setShowSpinner(true)

    expenses.forEach(item => {
      item.overheadCategoryDtoList = [];
    });

    products.forEach(item => {
      item.productCategoryDtoList = [];
    });

    axios
      .put("currentliabilities/vatUpdate/rates/" + project.id, {
        products: products,
        overheads: expenses
      })
      .then(async function(response) {

        //call global recalc or page refresh?
        console.log('done')

        let overheads = [];
        let products = [];

        //update products
        //update overheads
        await axios.get("revenuecos/" + project.id).then(function(response) {
          setRevenueAndCost(response.data);
          products = structuredClone(response.data)
        });
        await axios.get("overheads/" + project.id).then(function(response) {
          setOverheads(response.data);
          overheads = structuredClone(response.data)
        });

        let vatPackage = refreshVatPackage(products, overheads, vatRates, currentAssets);
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();

        setShowSpinner(false)
        setOpen(false);
      })
      .catch(function(error) {
        console.log(error);
      });


    //global recalculate
  }

  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"md"}
              >
                <div>
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit VAT Liability</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0}}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                        <ProductVatOutputAllocation products={products} vatRates={vatRates} values={values} recalculateCurrentLiabilities={recalculateCurrentLiabilities} manualRecalculation={manualRecalculation} calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}></ProductVatOutputAllocation>
                      </Grid>
                      <Grid item xs>
                        <ExpenseVatOutputAllocation expenses={expenses} products={products} vatRates={vatRates} values={values} recalculateCurrentLiabilities={recalculateCurrentLiabilities} manualRecalculation={manualRecalculation} calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}></ExpenseVatOutputAllocation>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>

                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      onClick={() => subVatChanges()}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>

                    <Button onClick={() => setOpen(false)} color="primary">
                      Close
                    </Button>

                  </DialogActions>
                </form>
                </div>
              </Dialog>
          )}
        </Formik>

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );;
}

export default EditCurrentLiabilityVAT;
