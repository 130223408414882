import React, { useState, useEffect, useRef } from "react";
import {
  Card as MuiCard,
  CardContent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import RowTitle from "../../../SharedComponents/RowTitle";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import useProject from "../../../../../hooks/useProject";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { StyledTableCell } from "../../../SharedComponents/utils/Utils";
import { customBlue } from "../../../../../theme/variants";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};


function ReleaseProfileInput(props) {

  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const {
    releaseProfileItem,
    releaseProfile,
    refreshData,
    setEditData,
    editData,
    setDebt
  } = props;

  useEffect(() => {

    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, 12));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function generateXaxisHeaders(startDateStr, lastActual=12) {
    const XaxisHeaders = [];
    const startDate = new Date(startDateStr);

    let currentOverallCount = 0;

    function addHeader(colour, yearLink) {
      let financialYearEnd = new Date(new Date(startDate).setMonth(startDate.getMonth() + 11));
      const financialYearText = "FY" + financialYearEnd.getFullYear() % 100

      for (let i = 0; i < 12; i++) {
        XaxisHeaders.push({
          type: currentOverallCount < lastActual ? 'Actual' : 'Forecast',
          financialYear: startDate.getFullYear(),
          financialMonth: startDate.toLocaleString("default", { month: "short" }),
          financialYearIndex: financialYearText,
          backgroundColor: colour,
          id: yearLink,
        });
        currentOverallCount++;
        startDate.setMonth(startDate.getMonth() + 1);
      }
    }

    addHeader(customBlue[900], "year0");
    addHeader(customBlue[800], "year1");
    addHeader(customBlue[600], "year2");
    addHeader(customBlue[400], "year3");
    addHeader(customBlue[200], "year4");
    addHeader(customBlue[100], "year5");

    return XaxisHeaders;
  }

  function ReleaseProfileHeader(props) {
    const { yHeader, name, index } = props;


    return (
      <StyledTableCell
        key={
          name.replaceAll(" ", "-") +
          "-" +
          yHeader.id +
          "-" +
          index
        }

        id={
          name.replaceAll(" ", "-") +
          "-" +
          yHeader.id +
          "-" +
          index
        }

        variant={"head"}

        align="right"

        sx={{
          ...tableStyling,
          backgroundColor: yHeader.backgroundColor,
          borderRightColor : 'white',
          borderOpacity : 0.5,
          minWidth: 100,
          maxWidth: 90,
          width: 90,
          zIndex: 5,
          borderRightStyle : 'dotted'
        }}
      >
        Month {index}
      </StyledTableCell>
    );
  }

  const tableStyling = {
    minWidth: 90,
    fontWeight: "bolder",
    color: "white",
  };


  function calculateBackgroundColor(releaseProfileMonthDtos) {
    const totalValue = releaseProfileMonthDtos.reduce((acc, month) => acc + (month.value || 0), 0);

    if (totalValue > 100) {
      return "#ffcccc"; // Red
    } else if (totalValue === 100) {
      return "#ccffcc"; // Green
    } else {
      return "#d3d3d3"; // Grey
    }
  }

  function renderHeavyContent() {
    return (
      <div>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0
          }}
          id={releaseProfileItem.releaseProfileDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0, zoom: zoomAtom }}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow

                >
                  <TableCell

                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10, // Ensure the header is above other content
                    }}
                  >
                    <h2>{releaseProfileItem.releaseProfileDto.name}</h2>
                    <AssumptionLabel label={releaseProfileItem.releaseProfileDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <ReleaseProfileHeader key={Math.random() * 10000000} yHeader={yHeader} name={releaseProfileItem.releaseProfileDto.name}
                                 index={index}></ReleaseProfileHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {releaseProfileItem.releaseProfileCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={row.overrideName} defaultBackground={calculateBackgroundColor(row.releaseProfileMonthDtos)}></RowTitle>

                    {row.releaseProfileMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={releaseProfile}
                        refreshItemData={refreshData}
                        setItems={setDebt}
                        categoryName={row.name}
                        parentName={releaseProfileItem.releaseProfileDto.name}
                        category={row}
                        monthIndex={month.monthIndex}
                        monthAttributeName={'releaseProfileMonthDtos'}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

      </div>
    );
  }



  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default ReleaseProfileInput;