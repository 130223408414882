import React from "react";
import { customBlue } from "../../../theme/variants";
import { StyledTableCell } from "./utils/Utils";

const tableStyling = {
  minWidth: 90,
  fontWeight: "bolder",
  color: "white",
};


export function generateXaxisHeaders(startDateStr, lastActual=12) {
  const XaxisHeaders = [];
  const startDate = new Date(startDateStr);

  let currentOverallCount = 0;

  function addHeader(colour, yearLink) {
    let financialYearEnd = new Date(new Date(startDate).setMonth(startDate.getMonth() + 11));
    const financialYearText = "FY" + financialYearEnd.getFullYear() % 100

    for (let i = 0; i < 12; i++) {
      XaxisHeaders.push({
        type: currentOverallCount < lastActual ? 'Actual' : 'Forecast',
        financialYear: startDate.getFullYear(),
        financialMonth: startDate.toLocaleString("default", { month: "short" }),
        financialYearIndex: financialYearText,
        backgroundColor: currentOverallCount < lastActual ? "#B1B1B1" : colour,
        id: yearLink,
      });
      currentOverallCount++;
      startDate.setMonth(startDate.getMonth() + 1);
    }
  }

  addHeader("#B1B1B1", "year0");
  addHeader(customBlue[800], "year1");
  addHeader(customBlue[600], "year2");
  addHeader(customBlue[400], "year3");
  addHeader(customBlue[200], "year4");
  addHeader(customBlue[100], "year5");

  return XaxisHeaders;
}

export default function MonthHeader(props) {
    const { yHeader, name, index } = props;

    return (
      <StyledTableCell
        key={
          name.replaceAll(" ", "-") +
          "-" +
          yHeader.id +
          "-" +
          index
        }

        id={
          name.replaceAll(" ", "-") +
          "-" +
          yHeader.id +
          "-" +
          index
        }

        variant={"head"}

        align="right"

            sx={{
              ...tableStyling,
              backgroundColor: yHeader.backgroundColor,
              borderRightColor: 'white',
              borderOpacity: 0.5,
              minWidth: 90,
              maxWidth: 90,
              width: 90,
              zIndex: 5,
              borderRightStyle: 'dotted'
            }}
        >
          {yHeader.type}
          <br/>
          {yHeader.financialMonth}
          <br/>
          {yHeader.financialYear}
          <br/>
          {yHeader.financialYearIndex}
        </StyledTableCell>
    );
}

export function generateXAnnualAxisHeaders(startDateStr) {
  var XaxisHeaders = [];

  var startDate = new Date(startDateStr);

  function addHeader(colour, yearLink, _type) {

      XaxisHeaders.push({
        type: _type,
        financialYear: startDate.getFullYear() + yearLink,
        financialMonth: startDate.toLocaleString("default", { month: "short" }),
        backgroundColor: colour,
        id: yearLink,
      });

  }

  addHeader("#B1B1B1", 0, "Actual");
  addHeader(customBlue[800], 1, "Forecast");
  addHeader(customBlue[600], 2, "Forecast");
  addHeader(customBlue[400], 3, "Forecast");
  addHeader(customBlue[200], 4, "Forecast");
  addHeader(customBlue[100], 5, "Forecast");

  return XaxisHeaders;
}

export function generateXAnnualAxisHeadersAnnual(startDateStr) {
  let XaxisHeaders = [];

  let startDate = new Date(startDateStr);

  startDate.setMonth(startDate.getMonth() + 11);

  function addHeader(colour, yearLink, _type) {
      let financialYearEnd = new Date(new Date(startDate).setFullYear(startDate.getFullYear() + yearLink));
      const financialYearText = "FY" + financialYearEnd.getFullYear() % 100
    XaxisHeaders.push({
      type: _type,
      financialYear: startDate.getFullYear() + yearLink,
      financialMonth: startDate.toLocaleString("default", { month: "short" }),
      financialYearIndex: financialYearText,
      backgroundColor: colour,
      id: yearLink
    });

  }

  addHeader("#B1B1B1", 0, "Actual");
  addHeader(customBlue[800], 1, "Forecast");
  addHeader(customBlue[600], 2, "Forecast");
  addHeader(customBlue[400], 3, "Forecast");
  addHeader(customBlue[200], 4, "Forecast");
  addHeader(customBlue[100], 5, "Forecast");

  return XaxisHeaders;
}


