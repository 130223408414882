import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead, Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";

import { generateXAnnualAxisHeadersAnnual } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
const Card = styled(MuiCard)(spacing);

const tableStyling = {
  minWidth: 90,
  fontWeight: "bolder",
  color: "white",
};

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};


function KpiInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);


  const {
    pnLItem,
    pnl,
    refreshPnLData,
    setPnl,
    setEditData,
    editData
  } = props;

  async function populateXaxis() {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
  }

  useEffect(() => {
    populateXaxis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }
  
  return (
    <React.Fragment key={key}>

      <Card
        mb={6}
        sx={{
          ...tableWidth,
          overflowX: "auto",
          overflow: "scroll",
          paddingTop: 0,
        }}
        id={pnLItem.pnLDto.name.replaceAll(" ", "-") + "-table"}
      >

        <CardContent>

          <Typography variant="h5" gutterBottom>
            Key Performance Indicators
          </Typography>
          <Typography variant="body2" gutterBottom sx={{paddingBottom: 10}}>
            This table illustrates the key performance indicators for your business.
          </Typography>


          <Table>
            {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
            <TableHead >
              <TableRow
                key={pnLItem.pnLDto.name + "-ROW"}
                id={pnLItem.pnLDto.name + "-ROW"}
              >
                <TableCell
                  key={pnLItem.pnLDto.name + "-BASE"}
                  id={pnLItem.pnLDto.name + "-BASE"}
                  style={{ position: "sticky", left: 0 }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    width: 200,
                    backgroundColor: "#F7F9FC",
                  }}
                >

                </TableCell>
                {XaxisHeaders.map((yHeader, index) => (
                  <TableCell
                    key={
                      pnLItem.pnLDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    id={
                      pnLItem.pnLDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    variant={"head"}
                    align="right"
                    sx={{
                      ...tableStyling,
                      backgroundColor: yHeader.backgroundColor,
                    }}
                  >
                    {yHeader.type}
                    <br />
                    {yHeader.financialMonth}
                    <br />
                    {yHeader.financialYear}
                    <br />
                    {yHeader.financialYearIndex}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {pnLItem.pnLCategoryDtoList.map((row) => (
                <TableRow key={row.id}
                          id={row.id}

                          onClick={() => handleClick(row.id)}>
                  <TableCell
                    component="th"
                    scope="row"
                    variant={"body"}
                    sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#F7F9FC",
                      zIndex: 1,
                      
                    }}
                  >
                    {row.overrideName === "" ? row.name : row.overrideName}

                  </TableCell>

                  {row.pnLMonthDtoList.map((month) => (
                    <PowdrCell
                      key={month.id + "-CELL"}
                      cellData={month}
                      ownerId={row.id}
                      editTableEnabled={editData}
                      items={pnl}
                      refreshItemData={refreshPnLData}
                      setItems={setPnl}
                    ></PowdrCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default KpiInput;
