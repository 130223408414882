import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useRecoilValue } from "recoil";
import { releaseProfileState } from "../../../SharedState/ReleaseProfileState";

export default function ReleaseSelect({ handleChangeOverride,
                                        itemId,
                                        type,
                                        hidden,
                                        initialValue,
                                        removeReleaseProfile }) {

  const [profile, setProfile] = React.useState('');

  const releaseProfileAtom = useRecoilValue(releaseProfileState);

  React.useEffect(() => {

    // If initialValue is provided, set it as the profile; otherwise, set the first available option
    if (initialValue !== undefined) {
      setProfile(releaseProfileAtom[0].releaseProfileCategoryDtos.find(profile => profile.id === initialValue.releaseProfile).id);
    }
  }, [initialValue, releaseProfileAtom]);

  React.useEffect(() => {

    //if hidden then we remove it from the releaseProfile[type] list
    if (hidden === true) {
      removeReleaseProfile(itemId, type, setProfile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  // Conditionally render the Box component if hidden is true
  if (hidden === true) {
    return null;
  }

  return (

    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Release Profile</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={profile}
          label="Release Profile"
          onChange={(event) => {
            setProfile(event.target.value);
            handleChangeOverride(event.target.value, itemId, type, setProfile);
          }}
          sx={{ minWidth: 200 }}
        >
          {releaseProfileAtom[0].releaseProfileCategoryDtos.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.overrideName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
