import React from "react";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel, Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik} from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { pnlState } from "../../../SharedState/PnLState";
import { currentLiabilitiesState } from "../../../SharedState/CurrentLiabilitiesState";


function updateCurrentLiability(values, project, currentliability, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentLiabilitiesAtom, pnlAtom, updatePowdrModelGlobalRef) {
  try {

    axios
      .put("currentliabilities/vatUpdate/" + project.id, {
        name: values.currentLiabilityName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        canDelete: values.canDelete,
        numberOfMonths: 2,
        directProductCosts: currentliability.directProductCosts,
        id: currentliability.id
      })
      .then(async function(response) {

        //update the atoms
        await axios.get("currentliabilities/" + project.id).then(function(response) {
          setCurrentLiabilitiesAtom(response.data);
        });

        await axios.get("balance/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(response.data, pnlAtom);
        });


        setTimeout(() => {
          // Code to run after 8 seconds
          setShowSpinner(false);
          setOpen(false);
        }, 8000); // Time is in milliseconds
      })
      .catch(function(error) {
        setShowSpinner(false);
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setShowSpinner(false);
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}


function EditCurrentLiabilityVATType(props) {

  const { project } = useProject();
  const { currentliability, open, setOpen, updatePowdrModelGlobalRef} = props;

  const [showSpinner, setShowSpinner] = React.useState(false);
  // const [numberOfMonthsState, setNumberOfMonthsState] = React.useState(2);

  const pnlAtom = useRecoilValue(pnlState);

  const setCurrentLiabilitiesAtom = useSetRecoilState(currentLiabilitiesState);

  const initialValues = {
    currentLiabilityName: currentliability?.name,
    assumptionFormat: currentliability?.assumption,
    balancePositionFlag: currentliability?.position,
    canDelete: currentliability?.canDelete,
    numberOfMonths: currentliability?.numberOfMonths,
  };


  // const handleChangeMonths = (event) => {
  //
  //   if (event.target.checked) {
  //     console.log(event.target.value)
  //     setNumberOfMonthsState(event.target.value);
  //     currentliability.numberOfMonths = event.target.value;
  //   }
  //
  // };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    setShowSpinner(true)

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("Changing the Assumption Type will reset your current liability, do you wish to continue\n(P & L & Balance Sheet data will remain)") === true) {
        updateCurrentLiability(values, project, currentliability, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentLiabilitiesAtom, pnlAtom, updatePowdrModelGlobalRef);
      } else {
        setShowSpinner(false);
        resetForm();
        setOpen(false);
      }
    } else {
      updateCurrentLiability(values, project, currentliability, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentLiabilitiesAtom, pnlAtom, updatePowdrModelGlobalRef);
    }

  };



  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["PERCENTAGE_OF_CERTAIN_OVERHEADS", "PERCENTAGE_OF_CERTAIN_REVENUE"].includes(values.assumptionFormat)}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Vat Assumption Type</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>


                    <DialogContentText>
                      Update the name of the current liability you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.currentLiabilityName && errors.currentLiabilityName)}
                      name="currentLiabilityName"
                      autoFocus
                      placeholder={"Current Liability Name"}
                      margin="dense"
                      id="currentLiabilityName"
                      label="Current Liability Name"
                      value={values.currentLiabilityName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.currentLiabilityName && errors.currentLiabilityName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        key="VAT_LIABILITY"
                        value="VAT_LIABILITY"
                        control={<Radio />}
                        label="Direct Input"
                      />
                      <FormControlLabel
                        key="VAT_MONTHLY_LIABILITY"
                        value="VAT_MONTHLY_LIABILITY"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        key="VAT_QUARTERLY_LIABILITY"
                        value="VAT_QUARTERLY_LIABILITY"
                        control={<Radio />}
                        label="Quarterly"
                      />
                    </RadioGroup>

                      </Grid>


                      {/*{values.assumptionFormat === "VAT_MONTHLY_LIABILITY" && <Grid item xs>*/}
                      {/*  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">*/}
                      {/*    <FormLabel component="legend">The number of months prior</FormLabel>*/}
                      {/*    <FormGroup>*/}

                      {/*      <RadioGroup aria-label="salary-duration"*/}
                      {/*                  name="numberOfMonths"*/}
                      {/*                  value={values.numberOfMonths}*/}
                      {/*                  onChange={handleChange}>*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="1"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="1 Month"*/}
                      {/*        />*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="2"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="2 Months"*/}
                      {/*        />*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="3"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="3 Months"*/}
                      {/*        />*/}
                      {/*      </RadioGroup>*/}


                      {/*    </FormGroup>*/}

                      {/*  </FormControl>*/}
                      {/*</Grid>*/}
                      {/*}*/}

                      {/*{values.assumptionFormat === "VAT_QUARTERLY_LIABILITY" && <Grid item xs>*/}
                      {/*  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">*/}
                      {/*    <FormLabel component="legend">The number of months prior</FormLabel>*/}
                      {/*    <FormGroup>*/}

                      {/*      <RadioGroup aria-label="salary-duration"*/}
                      {/*                  name="numberOfMonths"*/}
                      {/*                  value={values.numberOfMonths}*/}
                      {/*                  onChange={handleChange}>*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="1"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="1 Month (April, July, Oct,Jan)"*/}
                      {/*        />*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="2"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="2 Months (May,August,November, Feb)"*/}
                      {/*        />*/}
                      {/*        <FormControlLabel*/}
                      {/*          value="3"*/}
                      {/*          control={<Radio onChange={handleChangeMonths}/>}*/}
                      {/*          label="3 Months (June, Sept, Dec,March)"*/}
                      {/*        />*/}
                      {/*      </RadioGroup>*/}


                      {/*    </FormGroup>*/}

                      {/*  </FormControl>*/}
                      {/*</Grid>*/}
                      {/*}*/}

                    </Grid>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={showSpinner}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>

    </React.Fragment>
  );;
}

export default EditCurrentLiabilityVATType;
