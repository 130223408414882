import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditFixedAsset from "./EditFixedAsset";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { Skeleton } from "@mui/material";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('Additions/Capex', 'Additions should be a positive value');
helpTitles.set('Deductions/Sale of assets', 'Deductions should be a negative value');
helpTitles.set('Depreciation/Amortisation', 'Depreciation % should be a negative value');


function FixedAssetInput(props) {

  const { key } = useProject();
  const updatePowdrModelGlobalRef = useRef();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const tableRef = useRef(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    fixedAssetItem,
    fixedAssets,
    refreshData,
    setEditData,
    editData,
    setFixedAssets,
    loadData,
  } = props;

  const deleteFixedAsset = (handleClose) => {
    var config = {
      method: "delete",
      url: "fixedassets",
      data: fixedAssetItem.fixedAssetDto,
    };

    axios(config)
      .then(function (response) {
        // do something to trigger a redraw
       // console.log(response.data)
        setFixedAssets([...response.data]);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function renderHeavyContent() {
    return (
      <div>

        <PowdrNavBar
          item={fixedAssetItem.fixedAssetDto}
          categories={fixedAssetItem.fixedAssetCategoryDtos}
          deleteMethod={deleteFixedAsset}
          refreshDataMethod={loadData}
          editAssumptionComponent={      <EditFixedAsset
                                                         fixedasset={fixedAssetItem.fixedAssetDto}
                                                         open={openEdit}
                                                         setOpen={setOpenEdit}
                                                         updatePowdrModelGlobalRef={updatePowdrModelGlobalRef}>
                                                         </EditFixedAsset>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-FixedAsset-" + fixedAssetItem.fixedAssetDto.name + "-" + new Date().toDateString(),
            sheet : "Fixed Asset",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'fixedassets/fixedAssetShallow'}
          type={"FIXEDASSET"}
        ></PowdrNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={fixedAssetItem.fixedAssetDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={fixedAssetItem.fixedAssetDto.name + "-ROW"}
                  id={fixedAssetItem.fixedAssetDto.name + "-ROW"}
                >
                  <TableCell
                    key={fixedAssetItem.fixedAssetDto.name + "-BASE"}
                    id={fixedAssetItem.fixedAssetDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{fixedAssetItem.fixedAssetDto.name}</h2>
                    <AssumptionLabel label={fixedAssetItem.fixedAssetDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={fixedAssetItem.fixedAssetDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>


                {fixedAssetItem.fixedAssetCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={row.name} alt={helpTitles.get(row.name)}></RowTitle>

                    {row.fixedAssetMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={fixedAssets}
                        refreshItemData={refreshData}
                        setItems={setFixedAssets}
                        assumption={fixedAssetItem}
                        categoryName={row.name}
                        parentName={fixedAssetItem.fixedAssetDto.name}
                        category={row}
                        monthIndex={month.monthIndex}
                        monthAttributeName={'fixedAssetMonthDtos'}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default FixedAssetInput;
