import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function PaymentFrequencySelect({
                                                 itemId,
                                                 type,
                                                 hidden,
                                                 removePaymentFrequency,
                                                 hideFirstMonthOfAccrual = false,
                                                 handlePaymentFrequencyChange,
                                                 initialPaymentFrequencyDto,
                                               }) {
  // Initialize state with initial values or defaults
  const [paymentFrequencyWrapper, setPaymentFrequencyWrapper] = React.useState(
    initialPaymentFrequencyDto || {
      item: itemId,
      linkTypeEnum: type.toUpperCase(), // Use the 'type' prop to set 'linkTypeEnum'
      paymentFrequencyEnum: 'ANNUAL',
      paymentMonth: '1',
      firstMonthOfAccrual: '1',
    }
  );

  React.useEffect(() => {
    if (hidden === true) {
      removePaymentFrequency(itemId, type);
    } else {
      handlePaymentFrequencyChange(paymentFrequencyWrapper);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  // Conditionally render the component if hidden is true
  if (hidden === true) {
    return null;
  }

  return (
    <Box
      sx={{
        minWidth: 100,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        sx={{ minWidth: 175, marginRight: 2 }}
      >
        <InputLabel id="payment-frequency-label">Payment Frequency</InputLabel>
        <Select
          labelId="payment-frequency-label"
          id="payment-frequency-select"
          label="Payment Frequency"
          value={paymentFrequencyWrapper.paymentFrequencyEnum}
          onChange={(event) => {
            const selectedValue = event.target.value;

            // Compute the new state
            const updatedState = {
              ...paymentFrequencyWrapper,
              paymentFrequencyEnum: selectedValue,
            };

            // Update the state
            setPaymentFrequencyWrapper(updatedState);

            // Call the handler with the new state
            handlePaymentFrequencyChange(updatedState);
          }}
        >
          <MenuItem value="ANNUAL">Annual</MenuItem>
          <MenuItem value="QUARTERLY">Quarterly</MenuItem>
          <MenuItem value="SEMI_ANNUAL">Semi-annual</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        size="small"
        sx={{ minWidth: 175, marginRight: 2 }}
      >
        <InputLabel id="payment-month-label">Payment Month</InputLabel>
        <Select
          labelId="payment-month-label"
          id="payment-month-select"
          label="Payment Month"
          value={paymentFrequencyWrapper.paymentMonth}
          onChange={(event) => {
            const selectedValue = event.target.value;

            // Compute the new state
            const updatedState = {
              ...paymentFrequencyWrapper,
              paymentMonth: selectedValue,
            };

            // If hideFirstMonthOfAccrual is true, set firstMonthOfAccrual to paymentMonth
            if (hideFirstMonthOfAccrual) {
              updatedState.firstMonthOfAccrual = selectedValue;
            }

            // Update the state
            setPaymentFrequencyWrapper(updatedState);

            // Call the handler with the new state
            handlePaymentFrequencyChange(updatedState);
          }}
        >
          <MenuItem value="1">January</MenuItem>
          <MenuItem value="2">February</MenuItem>
          <MenuItem value="3">March</MenuItem>
          <MenuItem value="4">April</MenuItem>
          <MenuItem value="5">May</MenuItem>
          <MenuItem value="6">June</MenuItem>
          <MenuItem value="7">July</MenuItem>
          <MenuItem value="8">August</MenuItem>
          <MenuItem value="9">September</MenuItem>
          <MenuItem value="10">October</MenuItem>
          <MenuItem value="11">November</MenuItem>
          <MenuItem value="12">December</MenuItem>
        </Select>
      </FormControl>

      {/* Conditionally render First Month of Accrual dropdown */}
      {!hideFirstMonthOfAccrual && (
        <FormControl variant="outlined" size="small" sx={{ minWidth: 175 }}>
          <InputLabel id="first-month-accrual-label">
            First Month of Accrual
          </InputLabel>
          <Select
            labelId="first-month-accrual-label"
            id="first-month-accrual-select"
            label="First Month of Accrual"
            value={paymentFrequencyWrapper.firstMonthOfAccrual}
            onChange={(event) => {
              const selectedValue = event.target.value;

              // Compute the new state
              const updatedState = {
                ...paymentFrequencyWrapper,
                firstMonthOfAccrual: selectedValue,
              };

              // Update the state
              setPaymentFrequencyWrapper(updatedState);

              // Call the handler with the new state
              handlePaymentFrequencyChange(updatedState);
            }}
          >
            <MenuItem value="1">January</MenuItem>
            <MenuItem value="2">February</MenuItem>
            <MenuItem value="3">March</MenuItem>
            <MenuItem value="4">April</MenuItem>
            <MenuItem value="5">May</MenuItem>
            <MenuItem value="6">June</MenuItem>
            <MenuItem value="7">July</MenuItem>
            <MenuItem value="8">August</MenuItem>
            <MenuItem value="9">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
