import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import RowTitle from "../../../SharedComponents/RowTitle";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import getProjectId, { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import EditDebt from "./EditDebt";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { debtsState } from "../../../SharedState/DebtsState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { Skeleton } from "@mui/material";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};


function DebtInput(props) {
  const { key } = useProject();
  const updatePowdrModelGlobalRef = useRef();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);

  const setDebts = useSetRecoilState(debtsState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  const {
    debtItem,
    debt,
    refreshData,
    setEditData,
    editData,
    setDebt,
    loadData
  } = props;

  const deleteDebt = (handleClose) => {
    var config = {
      method: "delete",
      url: "debt",
      data: debtItem.debtDto,
    };

    axios(config)
      .then(async function(response) {

        let project = await getProjectId();

        setDebts(structuredClone(response.data))

        await axios.get("balance/" + project.id).then(function(response) {
          setBalanceSheet(response.data);
        });

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={debtItem.debtDto}
          categories={debtItem.debtCategoryDtos}
          deleteMethod={deleteDebt}
          refreshDataMethod={loadData}
          editAssumptionComponent={<EditDebt
                                             debt={debtItem.debtDto}
                                             open={openEdit}
                                             setOpen={setOpenEdit}
                                             updatePowdrModelGlobalRef={updatePowdrModelGlobalRef}>
          </EditDebt>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{
            fileName: "Powdr-Debt-" + debtItem.debtDto.name + "-" + new Date().toDateString(),
            sheet: "Debt",
            currentTableRef: tableRef.current
          }}
          shallowUpdateUrl={"debt/debtShallow"}
          type={"DEBT"}
        ></PowdrNavBar>


        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0
          }}
          id={debtItem.debtDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0, zoom: zoomAtom }}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={debtItem.debtDto.name + "-ROW"}
                  id={debtItem.debtDto.name + "-ROW"}
                >
                  <TableCell
                    key={debtItem.debtDto.name + "-BASE"}
                    id={debtItem.debtDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10, // Ensure the header is above other content
                    }}
                  >
                    <h2>{debtItem.debtDto.name}</h2>
                    <AssumptionLabel label={debtItem.debtDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={debtItem.debtDto.name}
                                 index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {debtItem.debtCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={row.name}></RowTitle>

                    {row.debtMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={debt}
                        refreshItemData={refreshData}
                        setItems={setDebt}
                        categoryName={row.name}
                        parentName={debtItem.debtDto.name}
                        category={row}
                        monthIndex={month.monthIndex}
                        monthAttributeName={'debtMonthDtos'}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      </div>
    );
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default DebtInput;
