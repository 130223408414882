import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import {
  calculateDecreaseAndReverseSign,
  calculatePercentageIncrease,
  calculatePercentageValue,
  percentage
} from "../../../SharedComponents/utils/PercentageUtils";
import { getValueOrOverriddenValue, getValueOrZero } from "../../../SharedComponents/utils/Utils";
import { createDecimal, parseNumberOrZero } from "../../../SharedComponents/utils/NumbersUtils";
import {
  calculateAnnualGrowthRate,
  calculateHeadcountNonUtilisation,
  calculateHeadcountsToRevenue,
  calculateHeadcountUtilisation, calculateMonthlyGrowthRate,
  calculatePercentageOfAnotherProduct, calculatePriceXQuantity,
  calculatePriceXQuantityBuilder,
  calculatePriceXQuantityDays, calculateRevenueAsAPercentageOfAValueBuilder
} from "./CalculationsContinued";
import { isValidNumber } from "../../../Analysis/RevenueDashboard/calculations/Calculations";
import { calculatePnl } from "../../../PLGroup/PL/calculations/Calculations";
const Decimal = require('decimal.js');

/**
 * Sums up all values within a set of categories in an array of data by month.
 * The data must be in a specific format with nested arrays and objects.
 *
 * @param {Array<Object>} data - The data to sum values from.
 * @param {Array<string>} categoryNames - The names of the categories to sum values for.
 * @returns {Array<number>} An array of summed values for each month. Each index represents a different month.
 *
 * @example
 *
 * let data = [
 *  {
 *    productCategoryDtoList: [
 *      {
 *        name: "Direct Product Costs",
 *        productMonthDtoList: [
 *          { value: 10 },
 *          { value: 20 }
 *        ]
 *      },
 *      // other categories...
 *    ]
 *  },
 *  // other items...
 * ];
 *
 * let sums = sumCategoryValues(data, ["Direct Product Costs"]);
 * console.log(sums);  // Output: [10, 20]
 *
 */
function sumCategoryValues(data, categoryNames) {
  let sums = [];


  // Iterate through each item in the data array
  data.forEach(item => {
    // Use optional chaining to safely access the nested 'productCategoryDtoList' array
    item?.productCategoryDtoList?.forEach(category => {
      // If the name of the category matches any of the desired categoryNames...
      if (categoryNames.includes(category?.name)) {
        category?.productMonthDtoList?.forEach((monthItem, index) => {

          // Use optional chaining to access the 'value' property. If 'value' is not a number, it is treated as 0
          let value = new Decimal(getValueOrOverriddenValue(monthItem) || 0);

          // Add the value to the corresponding month's sum in 'sums', or initialize it if it doesn't exist
          sums[index] = (sums[index] ? new Decimal(sums[index]) : new Decimal(0)).plus(value);
        });
        // Exit the loop once we found the first category that matches
        return true;
      }
      return false;
    });
  });

  // Convert Decimal objects back to normal number format
  return sums.map(decimal => decimal.toNumber());
}

/**
 * This method just calculates the values that the P&L needs for the Revenue From Products line and the Direct Products line.
 * It is used in the global recalc.
 *
 * @param productDataSetDtoList
 * @param pnl
 * @returns {Promise<*>}
 */
export function calculateProductSummaryTotalsValuesOnly(productDataSetDtoList, pnl) {

  const revenueFromProducts = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  const directProductCosts = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Direct product costs");

  let revenueTotals = sumCategoryValues(productDataSetDtoList, ["Revenue", "Closing recurring revenue"]);
  let directProductCostTotals = sumCategoryValues(productDataSetDtoList, "Direct product costs");

  for (let x = 0; x < 72; x++) {

    if (x >= getCurrentProject().firstForecast) {

      revenueFromProducts.pnLMonthDtoList[x].value = revenueTotals[x];

      directProductCosts.pnLMonthDtoList[x].value = directProductCostTotals[x];
    }

  }

  return pnl;
}


export async function calculateProductSummaryTotals(productDataSetDtoList, setProductSummary, pnlAtom) {

  const pnl = structuredClone(pnlAtom);

  // we need to create an object that the product summary component can use to display the totals for our products

  if (pnl === undefined) {
    return;
  }

  let productSummary = {
    productSummaryDto: { name: "Revenue & COS Summary", id: 999 },
    productSummaryCategoryDtoList: [
      {
        id: 0,
        name: 'Total Revenue',
        productSummaryMonthDtoList: []
      },
      {
        id: 1,
        name: 'Total Direct Costs',
        productSummaryMonthDtoList: []
      },
      {
        id: 2,
        name: 'Total Gross Profit',
        productSummaryMonthDtoList: []
      },
      {
        id: 3,
        name: 'Total Revenue in the P & L',
        productSummaryMonthDtoList: []
      }
    ]
  };

  // create 72 columns for each summary month dto list
  // and calculate the totals for each of them

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[0].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

    productSummary.productSummaryCategoryDtoList[1].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'costs' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

    productSummary.productSummaryCategoryDtoList[2].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'profit' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );


    if (i  < getCurrentProject().firstForecast) {
      productSummary.productSummaryCategoryDtoList[3].productSummaryMonthDtoList.push(
        {
          cellType: "CALCULATED",
          key: 'profit' + i,
          monthIndex: i,
          value: 0,
          valueFormat: "INTEGER",
        }
      );
    } else {
      productSummary.productSummaryCategoryDtoList[3].productSummaryMonthDtoList.push(
        {
          cellType: "DISABLED",
          key: 'profit' + i,
          monthIndex: i,
          value: 0,
          valueFormat: "INTEGER",
        }
      );
    }

  }


  const revenueFromProducts = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  const directProductCosts = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Direct product costs");


  let revenueTotals = sumCategoryValues(productDataSetDtoList, ["Revenue", "Closing recurring revenue"]);
  let directProductCostTotals = sumCategoryValues(productDataSetDtoList, "Direct product costs");

  //let sums = sumCategoryValues(data, "Direct Product Costs");
  let grossProfitTotals = sumCategoryValues(productDataSetDtoList, [ "Gross Profit"]);

  let revenueForHeadcountUtilisations =  sumCategoryValues(productDataSetDtoList.filter(product => product.productDto.assumption === "HEADCOUNT_UTILISATION"), ["Revenue"]);

  for (let x = 0; x < 72; x++) {

    productSummary.productSummaryCategoryDtoList[0].productSummaryMonthDtoList[x].value = revenueTotals[x];
    productSummary.productSummaryCategoryDtoList[1].productSummaryMonthDtoList[x].value = directProductCostTotals[x];
    productSummary.productSummaryCategoryDtoList[2].productSummaryMonthDtoList[x].value = (grossProfitTotals[x] || 0) + (revenueForHeadcountUtilisations[x] || 0);

  }


  // CALCULATE total revenue in p & l
  _.forEach(productDataSetDtoList, function(productDataSetDto) {

    for (let x = 0; x < 72; x++) {

      if (x < getCurrentProject().firstForecast) {

        productSummary
          .productSummaryCategoryDtoList[3]
          .productSummaryMonthDtoList[x].value =
          revenueFromProducts?.pnLMonthDtoList[x]?.value;

      }

    }

  });


  let pnlUpdateRequired = false;

  for (let x = 0; x < 72; x++) {

    if (x >= getCurrentProject().firstForecast) {

      if (revenueFromProducts.pnLMonthDtoList[x].value !== productSummary.productSummaryCategoryDtoList[0]
        .productSummaryMonthDtoList[x].value) {
        pnlUpdateRequired = true;
      }

      if (      directProductCosts.pnLMonthDtoList[x].value !== productSummary.productSummaryCategoryDtoList[1]
        .productSummaryMonthDtoList[x].value) {
        pnlUpdateRequired = true;
      }

      revenueFromProducts.pnLMonthDtoList[x].value = productSummary.productSummaryCategoryDtoList[0]
        .productSummaryMonthDtoList[x].value

      directProductCosts.pnLMonthDtoList[x].value = productSummary.productSummaryCategoryDtoList[1]
        .productSummaryMonthDtoList[x].value
    }

  }

  //todo - only do this if the PNL has been changed
  if (pnlUpdateRequired) {

    let pnl_ = structuredClone(pnl)
    calculatePnl(pnl_)
    //setPnlAtom(pnl_)
  }

  if (setProductSummary !== null) {
    setProductSummary(productSummary.productSummaryCategoryDtoList);
  }

  return productSummary;

}

function calculatePriceXQuantityCostInflation(productDataSetDtoList) {

  let priceXquantityCostInflations = productDataSetDtoList.filter(product => product.productDto.assumption === "PRICE_X_QUANTITY_COST_INFLATION");


  _.forEach(priceXquantityCostInflations, function (product) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        if (i >= getCurrentProject().firstForecast && i >= 12) {
          //calculate Sales price per unit sold (ex-VAT)
          product.productCategoryDtoList[2].productMonthDtoList[i].value =
            calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[2].productMonthDtoList[i - 1]), product.productCategoryDtoList[1].productMonthDtoList[i].value);
        }

        //calculate revenue
        product.productCategoryDtoList[3].productMonthDtoList[i].value =
          product.productCategoryDtoList[0].productMonthDtoList[i].value * //quantity sold
          getValueOrOverriddenValue(product.productCategoryDtoList[2].productMonthDtoList[i]);  //sales price per unit sold


        if (i >= getCurrentProject().firstForecast && i >= 12) {
          //calculate Cost price per unit sold (ex-VAT)
          product.productCategoryDtoList[5].productMonthDtoList[i].value =
            calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i - 1]), product.productCategoryDtoList[4].productMonthDtoList[i].value);
        }

        if (i < getCurrentProject().firstForecast && i > 11) {

          product.productCategoryDtoList[1].productMonthDtoList[i].value //sales price growth rates
            = isValidNumber(((product.productCategoryDtoList[2].productMonthDtoList[i].value - product.productCategoryDtoList[2].productMonthDtoList[i - 1].value)
            / product.productCategoryDtoList[2].productMonthDtoList[i - 1].value) * 100);

          product.productCategoryDtoList[4].productMonthDtoList[i].value //cost price growth rates
            = isValidNumber(((product.productCategoryDtoList[5].productMonthDtoList[i].value - product.productCategoryDtoList[5].productMonthDtoList[i - 1].value)
            / product.productCategoryDtoList[5].productMonthDtoList[i - 1].value) * 100);

        }



        //calculate Direct product costs
        product.productCategoryDtoList[6].productMonthDtoList[i].value =    //direct product costs
          product.productCategoryDtoList[0].productMonthDtoList[i].value *  //quantity sold
          getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]); //cost price growth rates

        //calculate Gross Profit
        product.productCategoryDtoList[7].productMonthDtoList[i].value =
          getValueOrOverriddenValue(product.productCategoryDtoList[3].productMonthDtoList[i]) +  //revenue
          getValueOrOverriddenValue(product.productCategoryDtoList[6].productMonthDtoList[i]);   //costs

        //calculate Product Margin
        product.productCategoryDtoList[8].productMonthDtoList[i].value =
          percentage(product.productCategoryDtoList[7].productMonthDtoList[i].value, getValueOrOverriddenValue(product.productCategoryDtoList[3].productMonthDtoList[i]));


      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }


  });

}

function calculateSubscriptionModelling(productDataSetDtoList) {

  let subscriptionModels = productDataSetDtoList.filter(product => product.productDto.assumption === "SUBSCRIPTION_MODEL");

  _.forEach(subscriptionModels, function (product) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //nb The order of the calculations is important for Subscription modelling. So we have to do "New revenue calculations" first
        //because other parts of the calculation rely on it.
        //then Churn - lost customers calculation
        //then Price increases calculation:
        //then Churn - price erosion calcualtion:
        //then the opening/closing recurring revenue calc


        //opening revenue = closing recurring revenue from previous month
        if (i > 0) {
          product.productCategoryDtoList[0].productMonthDtoList[i].value = // Opening Revenue
            product.productCategoryDtoList[7].productMonthDtoList[i - 1].value  //Closing recurring revenue LAST MONTH
        }


        //First 12 months of Sales price per unit sold (ex-VAT) is NOT calculated, it is a manual entry (regardless of the number of actuals in the project)
        if (i >= getCurrentProject().firstForecast && i > 11) {
          product.productCategoryDtoList[16].productMonthDtoList[i].value = //Sales price per unit sold (ex-VAT)
            calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[16].productMonthDtoList[i - 1]), //Quantity sold from LAST MONTH
              product.productCategoryDtoList[15].productMonthDtoList[i].value) //Sales price growth rate
        }

        //"New revenue calculations"
        if (i < getCurrentProject().firstForecast) {
          product.productCategoryDtoList[17].productMonthDtoList[i].value = // New Revenue
            product.productCategoryDtoList[14].productMonthDtoList[i].value *  //Quantity Sold
            getValueOrOverriddenValue(product.productCategoryDtoList[16].productMonthDtoList[i]);   //Sales price per unit sold (ex-VAT)
        } else {

          product.productCategoryDtoList[17].productMonthDtoList[i].value = // New Revenue
            product.productCategoryDtoList[14].productMonthDtoList[i].value *  //Quantity Sold
            getValueOrOverriddenValue(product.productCategoryDtoList[16].productMonthDtoList[i]);   //Sales price per unit sold (ex-VAT)
        }

        // Churn - lost customers calculation
        product.productCategoryDtoList[24].productMonthDtoList[i].value = // Churn lost customers - new revenue
          product.productCategoryDtoList[23].productMonthDtoList[i].value *  //Average price
          product.productCategoryDtoList[22].productMonthDtoList[i].value;   //Quantity lost

        // Price increases calculation:
        if (i >= 12) {
          product.productCategoryDtoList[20].productMonthDtoList[i].value = // Price increase revenue
            calculatePercentageValue(product.productCategoryDtoList[0].productMonthDtoList[i].value, //Quantity sold from LAST MONTH
              product.productCategoryDtoList[19].productMonthDtoList[i].value) //price increase % of opening revenue
        }

        // Churn - price erosion calcualtion:
        if (i >= 12) {
          product.productCategoryDtoList[27].productMonthDtoList[i].value = // Price increase revenue
            calculatePercentageValue(product.productCategoryDtoList[0].productMonthDtoList[i].value, //Quantity sold from LAST MONTH
              product.productCategoryDtoList[26].productMonthDtoList[i].value) //price increase % of opening revenue
        }

        //Direct product costs
        if (i >= getCurrentProject().firstForecast) {
          product.productCategoryDtoList[12].productMonthDtoList[i].value = // Direct product costs
              calculateDecreaseAndReverseSign(product.productCategoryDtoList[7].productMonthDtoList[i].value, //Closing recurring revenue
              product.productCategoryDtoList[11].productMonthDtoList[i].value)  //Margin assumption
        }


        //then the opening/closing recurring revenue calc
        if (i >= 12) {

          product.productCategoryDtoList[2].productMonthDtoList[i].value = //Price increase
            product.productCategoryDtoList[20].productMonthDtoList[i].value // New revenue calculation: - New Revenue


          product.productCategoryDtoList[5].productMonthDtoList[i].value = //Churn - price erosion
            product.productCategoryDtoList[27].productMonthDtoList[i].value // Churn price erosion

        }

        //then the opening/closing recurring revenue calc
        if (i >= getCurrentProject().firstForecast) {
          product.productCategoryDtoList[1].productMonthDtoList[i].value = //New Revenue
            product.productCategoryDtoList[17].productMonthDtoList[i].value // New revenue calculation: - New Revenue

          product.productCategoryDtoList[4].productMonthDtoList[i].value = //Churn - lost customers
            product.productCategoryDtoList[24].productMonthDtoList[i].value // Churn lost customers - new revenue

        }

        if (i >= getCurrentProject().firstForecast) {
          product.productCategoryDtoList[7].productMonthDtoList[i].value =
            getValueOrZero(product.productCategoryDtoList[0].productMonthDtoList[i].value) +
              getValueOrZero(product.productCategoryDtoList[1].productMonthDtoList[i].value) +
                getValueOrZero(product.productCategoryDtoList[2].productMonthDtoList[i].value) +
                  getValueOrZero(product.productCategoryDtoList[3].productMonthDtoList[i].value) +
                    getValueOrZero(product.productCategoryDtoList[4].productMonthDtoList[i].value) +
                      getValueOrZero(product.productCategoryDtoList[5].productMonthDtoList[i].value) +
                        getValueOrZero(product.productCategoryDtoList[6].productMonthDtoList[i].value);
        }

        product.productCategoryDtoList[28].productMonthDtoList[i].value = // Gross Profit
          getValueOrZero(product.productCategoryDtoList[7].productMonthDtoList[i].value) +
          getValueOrZero(product.productCategoryDtoList[12].productMonthDtoList[i].value);

        //calculate Total customers
        if (i >= getCurrentProject().firstForecast) {

          let totalCustomerDecimal = // Total customers
            createDecimal(product.productCategoryDtoList[9].productMonthDtoList[i - 1].value).add(  //last month's total customers
                createDecimal(product.productCategoryDtoList[14].productMonthDtoList[i].value)).add( //quantity sold
                  createDecimal(product.productCategoryDtoList[22].productMonthDtoList[i].value)); //quantity lost

          product.productCategoryDtoList[9].productMonthDtoList[i].value = parseNumberOrZero(totalCustomerDecimal.toNumber().toString().replace(/^0+/, ''));
        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }

  });

}

function calculateDirectInputs(productDataSetDtoList) {

  let directInputs = productDataSetDtoList.filter(product => product.productDto.assumption === "DIRECT_INPUT");

  _.forEach(directInputs, function (product) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        if (i >= getCurrentProject().firstForecast) {

          product.productCategoryDtoList[2].productMonthDtoList[i].value = // Gross profit
            product.productCategoryDtoList[0].productMonthDtoList[i].value +  //Revenue
            product.productCategoryDtoList[1].productMonthDtoList[i].value;   //Direct Costs

        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }

  });
}


function calculateConsolidatedProducts(productDataSetDtoList, consolidatedProducts) {

  let consolidatedProductAssumptions = productDataSetDtoList.filter(product =>
    product.productDto.assumption === "REVENUE_DIVISIONAL_CONSOLIDATION"
  );

  _.forEach(consolidatedProductAssumptions, function(consolidatedProductAssumption) {
    // Get all the products (from the consolidated list) that link to this assumption
    let productsToSum = consolidatedProducts.filter(product =>
      consolidatedProductAssumption.productDto.products.includes(product.productDto.id)
    );

    let revenueTotals = new Array(72).fill(0);
    let directProductCostsTotals = new Array(72).fill(0);
    let grossProfitTotals = new Array(72).fill(0);

    _.forEach(productsToSum, function(productToSum) {
      // Find the category 'Value In P&L' from productCategoryDtoList
      let revenueTotal = productToSum.productCategoryDtoList.find(category => category.name === 'Revenue' || category.name === 'Closing recurring revenue');
      let directProductCostsTotal = productToSum.productCategoryDtoList.find(category => category.name === 'Direct product costs');

      let grossProfitTotal;
      if (productToSum.productDto.assumption === "HEADCOUNT_UTILISATION") {
        grossProfitTotal = productToSum.productCategoryDtoList.find(category => category.name === 'Revenue');
      } else {
        grossProfitTotal = productToSum.productCategoryDtoList.find(category => category.name === 'Gross Profit');
      }

      calculateTotal(revenueTotals, revenueTotal);
      calculateTotal(directProductCostsTotals, directProductCostsTotal);
      calculateTotal(grossProfitTotals, grossProfitTotal);

    });

    let consolidatedRevenue = consolidatedProductAssumption.productCategoryDtoList.find(category => category.name === 'Revenue' || category.name === 'Closing recurring revenue');
    let consolidatedProductCosts = consolidatedProductAssumption.productCategoryDtoList.find(category => category.name === 'Direct product costs');
    let consolidatedGrossProfit = consolidatedProductAssumption.productCategoryDtoList.find(category => category.name === 'Gross Profit');

    _.forEach(consolidatedRevenue.productMonthDtoList, function(month, i) {month.value = revenueTotals[i];});
    _.forEach(consolidatedProductCosts.productMonthDtoList, function(month, i) {month.value = directProductCostsTotals[i];});
    _.forEach(consolidatedGrossProfit.productMonthDtoList, function(month, i) {month.value = grossProfitTotals[i];});

  });
}

function calculateTotal(arrayToTotal, categoryToTotal) {

  if (categoryToTotal && categoryToTotal.productMonthDtoList) {
    _.forEach(categoryToTotal.productMonthDtoList, function(monthToSum, i) {

      if (arrayToTotal[i] === undefined) {
        arrayToTotal[i] = 0; // Initialize to 0 if undefined
      }
      arrayToTotal[i] += getValueOrOverriddenValue(monthToSum);
    });
  }
}

export function calculateRevenueSummaryTotals(productDataSetDtoList, pnlAtom, setPnlAtom) {

  if (!_.isEmpty(pnlAtom)) {

    let pnl = structuredClone(pnlAtom);

    let revenueTotals = sumCategoryValues(productDataSetDtoList, ["Revenue", "Closing recurring revenue"]);
    let directProductCostTotals = sumCategoryValues(productDataSetDtoList, "Direct product costs");

    const revenueFromProducts = pnl.pnLCategoryDtoList.find(pnlCategory => pnlCategory.overrideName === "Revenue from products");

    const directProductCosts = pnl.pnLCategoryDtoList.find(pnlCategory => pnlCategory.overrideName === "Direct product costs");

    for (let x = 0; x < 72; x++) {
      if (x >= getCurrentProject().firstForecast) {
        revenueFromProducts.pnLMonthDtoList[x].value = revenueTotals[x];
        directProductCosts.pnLMonthDtoList[x].value = directProductCostTotals[x];
      }
    }

    calculatePnl(pnl)
    setPnlAtom(structuredClone(pnl));

    return pnl;

  }


}

export function calculateLoanAssetBalance(productDataSetDtoList, currentAssetsInBalance) {

  let loanAssetSisterProducts = productDataSetDtoList.filter(product => product.productDto.assumption === "LOAN_ASSET_SISTER_PRODUCT");

  _.forEach(loanAssetSisterProducts, function (product) {

    let currentAssetTwin = currentAssetsInBalance.balanceCategoryDtos.find(category => category.overrideName === product.productDto.name);


    const revenue = product.productCategoryDtoList.find(category => category.name === "Revenue");
    const grossProfit = product.productCategoryDtoList.find(category => category.name === "Gross Profit");

    for (let x = 0; x < 72; x++) {
      revenue.productMonthDtoList[x].value = currentAssetTwin.balanceMonthDtos[x + 1].value;
      grossProfit.productMonthDtoList[x].value = currentAssetTwin.balanceMonthDtos[x + 1].value;
    }

  });

}

export function calculateLoanAsset(productDataSetDtoList, currentAssets) {

  let loanAssetSisterProducts = productDataSetDtoList.filter(product => product.productDto.assumption === "LOAN_ASSET_SISTER_PRODUCT");

  _.forEach(loanAssetSisterProducts, function (product) {

    let currentAssetTwin = currentAssets.find(currentAsset => currentAsset.currentAssetDto.id === product.productDto.assetLoanId);
    let closingCategory = currentAssetTwin.currentAssetCategoryDtos.find(category => category.name === 'Interest Charge');

    const revenue = product.productCategoryDtoList.find(category => category.name === "Revenue");
    const grossProfit = product.productCategoryDtoList.find(category => category.name === "Gross Profit");

    if (closingCategory !== undefined) {
      for (let x = 0; x < 72; x++) {
        revenue.productMonthDtoList[x].value = closingCategory.currentAssetMonthDtos[x].value;
        grossProfit.productMonthDtoList[x].value = closingCategory.currentAssetMonthDtos[x].value;
      }
    }

  });

}

export function calculateRevenueAndCOS(productDataSetDtoList, headcounts, consolidatedProducts) {

  calculatePriceXQuantityCostInflation(productDataSetDtoList);

  calculateSubscriptionModelling(productDataSetDtoList);

  calculateDirectInputs(productDataSetDtoList);

  calculateHeadcountsToRevenue(productDataSetDtoList, headcounts);

  calculateHeadcountUtilisation(productDataSetDtoList, headcounts);

  calculateHeadcountNonUtilisation(productDataSetDtoList);

  calculatePriceXQuantityBuilder(productDataSetDtoList);

  calculateRevenueAsAPercentageOfAValueBuilder(productDataSetDtoList)

  calculatePriceXQuantityDays(productDataSetDtoList);

  calculatePercentageOfAnotherProduct(productDataSetDtoList);

  calculateMonthlyGrowthRate(productDataSetDtoList);

  calculateAnnualGrowthRate(productDataSetDtoList);

  calculatePriceXQuantity(productDataSetDtoList);

  calculateConsolidatedProducts(productDataSetDtoList, consolidatedProducts);

}