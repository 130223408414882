import _ from "lodash";
import { calculateCorkscrew } from "../../../SharedComponents/calculations/SimpleCorkscrew";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";
import { getNumberOfDaysInMonth, getValueOrOverriddenValue, reverseSign } from "../../../SharedComponents/utils/Utils";

export function calculateInventoryModelling(currentAssets, pnl) {

  let inventoryModel = currentAssets.find(currentAsset => currentAsset.currentAssetDto.assumption === "INVENTORY_MODELLING");

  //Populate product costs from P&L
  let directProductCosts = pnl.pnLCategoryDtoList.find(category => category.overrideName === 'Direct product costs');

  if (inventoryModel && directProductCosts) {
    _.forEach(inventoryModel.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(assumptionMonthDto, i) {
      assumptionMonthDto.value = directProductCosts.pnLMonthDtoList[i].value;
    });

    _.forEach(inventoryModel.currentAssetCategoryDtos[5].currentAssetMonthDtos, function(assumptionMonthDto, i) {
      // only for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        assumptionMonthDto.value = directProductCosts.pnLMonthDtoList[i].value;
      }
    });
  }


  //this works very similar to a corkscrew
  if (inventoryModel) {
    _.forEach(inventoryModel.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(assumptionMonthDto, i) {


      // only for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {

        inventoryModel.currentAssetCategoryDtos[3].currentAssetMonthDtos[i].value = inventoryModel.currentAssetCategoryDtos[7].currentAssetMonthDtos[i - 1].value; //set opening to last months closing

        let monthsAdvance = inventoryModel.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value; //Inventory holding period in months


        let monthsAdvanceTotal = monthsAdvance + i;

        if ((i + monthsAdvance) >  71) {

          monthsAdvanceTotal = 71; //if the advance is greater than the forecast months, then use the last forecastmonth
        }


        //New inventory bought
        inventoryModel.currentAssetCategoryDtos[4].currentAssetMonthDtos[i].value = reverseSign(inventoryModel.currentAssetCategoryDtos[0].currentAssetMonthDtos[monthsAdvanceTotal].value);

        //get the value of the closing, from the previous month
        inventoryModel.currentAssetCategoryDtos[7].currentAssetMonthDtos[i].value =
          getValueOrZero(inventoryModel.currentAssetCategoryDtos[3].currentAssetMonthDtos[i].value) +
            getValueOrZero(inventoryModel.currentAssetCategoryDtos[4].currentAssetMonthDtos[i].value) +
              getValueOrZero(inventoryModel.currentAssetCategoryDtos[5].currentAssetMonthDtos[i].value) +
                getValueOrZero(inventoryModel.currentAssetCategoryDtos[6].currentAssetMonthDtos[i].value);
      }

    });
  }

}

function getValueOrZero(item) {
  if (_.isNil(item) || isNaN(item)) {
    return 0;
  } else {
    return item;
  }
}

function calculateLoanAssets(currentAssets) {

  const loanAssets = currentAssets
    .filter(currentAsset => currentAsset.currentAssetDto.assumption === "LOAN_ASSET");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(loanAssets, function(loanAsset) {

    let annualBaseRateCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Annual Base Rate %');
    let annualInterestRateCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Annual Interest Rate %');
    let interestChargeCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Interest Charge');
    let openingCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Opening');
    let additionCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Addition');
    let deductionCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Deduction');
    let closingCategory = loanAsset.currentAssetCategoryDtos.find(category => category.name === 'Closing');

    if (annualBaseRateCategory !== undefined) {

      _.forEach(loanAsset.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(assumptionMonthDto, i) {

        // only do the Corkscrew for forecast values
        if (i > getCurrentProject().firstBalanceForecast - 1) {

          openingCategory.currentAssetMonthDtos[i].value = closingCategory.currentAssetMonthDtos[i - 1].value;

          //get the value of the closing, from the previous month
          closingCategory.currentAssetMonthDtos[i].value =
            openingCategory.currentAssetMonthDtos[i].value +
            additionCategory.currentAssetMonthDtos[i].value +
            deductionCategory.currentAssetMonthDtos[i].value;
        }

        // Calculate the interest for every month, including the Actuals
        let totalInterest = annualBaseRateCategory.currentAssetMonthDtos[i].value //base rate
          + annualInterestRateCategory.currentAssetMonthDtos[i].value //interest rate


        let interestCharges = (closingCategory.currentAssetMonthDtos[i].value / 100 * totalInterest) / 12;


        //Set the "Interest Charge" row
        interestChargeCategory.currentAssetMonthDtos[i].value = interestCharges;

      });

    }
  });

}

function buildRowForMatrix(releaseProfile, index, month) {
  let matrixRow = [];

  // Fill with zeros until we reach this month's index
  for (let i = 0; i < index; i++) {
    matrixRow.push({ index: month.monthIndex, value: 0 });
  }

  _.forEach(releaseProfile.releaseProfileMonthDtos, function(releaseMonth, n) {
    let newAccrualMonthValue = (getValueOrOverriddenValue(month) / 100) * releaseMonth.value; // Work out the percentage value

    // Make the newAccrualMonthValue a negative number
    newAccrualMonthValue *= -1;

    // Push the result into the matrix row
    matrixRow.push({ index: month.monthIndex, value: newAccrualMonthValue });
  });

  return matrixRow;
}


function populateProductReleaseProfilesMatrix(accrual, productsAtom, releaseProfilesAtom, releaseMatrix) {

  if (releaseProfilesAtom === undefined || releaseProfilesAtom.length === 0) {
    return;
  }

  _.forEach(accrual.currentAssetDto.productsReleaseProfile, function(accrualProduct) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let product = productsAtom.find(product => product.productDto.id === accrualProduct.item);

    //find the releaseProfile
    let releaseProfile = releaseProfilesAtom[0].releaseProfileCategoryDtos.find(releaseProfile => releaseProfile.id === accrualProduct.releaseProfile);

    if (product !== undefined && releaseProfile !== undefined) {

      //for each month of the model (72) calculate the release
      //find the closing category (or equivalent) for the calculation (for overheads and salaries we just pull them from the P&L)
      let revenueCategory = product.productCategoryDtoList.find(category => category.name === "Revenue" || category.name === "Closing recurring revenue");

      //add these values to an array { accrualItem : {item attributes..., itemReleaseMonths: []}}
      let matrix = {
        name: product.productDto.name,
        itemId: accrualProduct.item,
        rows: []
      };

      _.forEach(revenueCategory.productMonthDtoList, function(month, index) {

        //so for each month's value, we calculate the entire release profile, effectively you end up with 72x72 grid matrix, the release profile is applied to all of them
        let matrixRow = buildRowForMatrix(releaseProfile, index, month);
        matrix.rows.push(matrixRow);
      });

      releaseMatrix.indexes.push(matrix);
    }

  });
}

function populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, releaseProfileAccrualItems, pnlAttribute) {
  if (releaseProfilesAtom === undefined || releaseProfilesAtom.length === 0) {
    return;
  }

  _.forEach(releaseProfileAccrualItems, function(accrualOverhead) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let overheadPnlCategory = pnlAtom.pnLCategoryDtoList.find(category => category[pnlAttribute] === accrualOverhead.item);
    //find the releaseProfile
    let releaseProfile = releaseProfilesAtom[0].releaseProfileCategoryDtos.find(releaseProfile => releaseProfile.id === accrualOverhead.releaseProfile);

    if (overheadPnlCategory !== undefined && releaseProfile !== undefined) {
      //for each month of the model (72) calculate the release
      //find the closing category (or equivelant) for the calcualation (for overheads and salaries we just pull them from the P&L)

      //add these values to an array { accrualItem : {item attributes..., itemReleaseMonths: []}}
      let matrix = {
        name: overheadPnlCategory.overrideName,
        itemId: accrualOverhead.item,
        rows: []
      };

      _.forEach(overheadPnlCategory.pnLMonthDtoList, function(month, index) {

        //so for each months value, we calculate the entire release profile, effectively you end up with 72x72 grid matrix, the release profile is applied to all of them
        let matrixRow = buildRowForMatrix(releaseProfile, index, month);
        matrix.rows.push(matrixRow);
      });

      releaseMatrix.indexes.push(matrix);
    }


  });
}

function calculateOverheadAndSalaryNewAccrualValues(releaseProfileAccrualItems, newAccrualsArray, pnlAtom, pnlAttribute) {

  _.forEach(releaseProfileAccrualItems, function(accrualOverhead) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let overheadPnlCategory = pnlAtom.pnLCategoryDtoList.find(category => category[pnlAttribute] === accrualOverhead.item);

    if (overheadPnlCategory !== undefined) {
      _.forEach(overheadPnlCategory.pnLMonthDtoList, function(month, index) {

        newAccrualsArray[index] += getValueOrOverriddenValue(month);
      });

    }


  });

}

function calculateNewAccrualValues(accrual, productsAtom, pnlAtom) {

  let newAccrualsArray = new Array(72).fill(0);

  //find all the products and their categories and then create a entry in the newAccrualsArray
  _.forEach(accrual.currentAssetDto.productsReleaseProfile, function(accrualProduct) {

    //find the product by the item
    let product = productsAtom.find(product => product.productDto.id === accrualProduct.item);

    if (product !== undefined) {

      let productCategoryObject = product.productCategoryDtoList.find(category => category.name === "Revenue" || category.name === "Closing recurring revenue");

      _.forEach(productCategoryObject.productMonthDtoList, function(month, index) {

        newAccrualsArray[index] += getValueOrOverriddenValue(month);
      });
    }

  });

  //do the same for overheads and salaries
  calculateOverheadAndSalaryNewAccrualValues(accrual.currentAssetDto.overheadsReleaseProfile, newAccrualsArray, pnlAtom, "overheadId");

  calculateOverheadAndSalaryNewAccrualValues(accrual.currentAssetDto.salariesReleaseProfile, newAccrualsArray, pnlAtom, "headcountId");

  return newAccrualsArray;
}

function createAccrualReleaseTotals(releaseMatrix) {
  let accrualReleaseArray = new Array(72).fill(0);

  //lets' build up our totals
  accrualReleaseArray.forEach((item, rowIndex) => {

    releaseMatrix.indexes.forEach(product => {

      product.rows.forEach((row) => {

        accrualReleaseArray[rowIndex] += row[rowIndex].value;

      });

    });

  });

  return accrualReleaseArray;
}


function calculateAccruals(currentAssets, releaseProfilesAtom, productsAtom, pnlAtom) {

  let accruals = currentAssets.filter(currentAsset => currentAsset.currentAssetDto.assumption === "ACCRUAL");

  _.forEach(accruals, function(accrual, i) {

    let newAccrualArray = calculateNewAccrualValues(accrual, productsAtom, pnlAtom);

      //calculate the release profiles for each of the accrual items
    let releaseMatrix = {indexes: []};

    populateProductReleaseProfilesMatrix(accrual, productsAtom, releaseProfilesAtom, releaseMatrix);

    populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, accrual.currentAssetDto.overheadsReleaseProfile, "overheadId");

    populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, accrual.currentAssetDto.salariesReleaseProfile, "headcountId");

    let accrualReleaseTotals = createAccrualReleaseTotals(releaseMatrix);

    //it's now basically like a regular corkscrew
    let openingCategory = accrual.currentAssetCategoryDtos.find(category => category.name === "Opening"); //opening
    let newAccrualCategory = accrual.currentAssetCategoryDtos.find(category => category.name === "New Accrual"); //New Accrual
    let accrualReleaseCategory = accrual.currentAssetCategoryDtos.find(category => category.name === "Accrual Release"); //Accrual Release
    let adjustmentCategory = accrual.currentAssetCategoryDtos.find(category => category.name === "Adjustment"); //Adjustment
    let closingCategory = accrual.currentAssetCategoryDtos.find(category => category.name === "Closing"); //closing

    _.forEach(openingCategory.currentAssetMonthDtos, function(openingMonth, i) {

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        openingCategory.currentAssetMonthDtos[i].value = closingCategory.currentAssetMonthDtos[i - 1].value;
        newAccrualCategory.currentAssetMonthDtos[i].value = newAccrualArray[i];
        accrualReleaseCategory.currentAssetMonthDtos[i].value = accrualReleaseTotals[i];

        closingCategory.currentAssetMonthDtos[i].value =
          openingCategory.currentAssetMonthDtos[i].value +
            newAccrualCategory.currentAssetMonthDtos[i].value +
            accrualReleaseCategory.currentAssetMonthDtos[i].value +
            adjustmentCategory.currentAssetMonthDtos[i].value;
      }

    });

  });

}

function calculatePrepayments(currentAssets, pnl) {

  let prepayments = currentAssets.filter(currentAsset => currentAsset.currentAssetDto.assumption === "PREPAYMENTS");

  _.forEach(prepayments, function(prepayment, i) {

    //it's now basically like a regular corkscrew
    let openingCategory = prepayment.currentAssetCategoryDtos.find(category => category.name === "Opening"); //opening
    let adjustmentCategory = prepayment.currentAssetCategoryDtos.find(category => category.name === "Adjustment"); //Adjustment
    let closingCategory = prepayment.currentAssetCategoryDtos.find(category => category.name === "Closing"); //closing

    //populate the Accrual rows
      //for each row, find the matching overhead from the OverheadsAtom
      //then get the 'closing' category from that overhead
      //then copy the values to the Accruals row
    let accrualCategories = prepayment.currentAssetCategoryDtos.filter(category => category.name === 'P&L release');
    let paymentCategories = prepayment.currentAssetCategoryDtos.filter(category => category.name === 'Payment');

    _.forEach(accrualCategories, function(accrualCategory, i) {
        //find the matching overhead
        let accrualOverheadPnlCategory = pnl.pnLCategoryDtoList.find(pnlCategory => pnlCategory.overheadId === accrualCategory.overheadId);

        _.forEach(accrualCategory.currentAssetMonthDtos, function(accrualMonth, i) {
          accrualMonth.value = accrualOverheadPnlCategory.pnLMonthDtoList[i].value;
        });

    });


    let startDate = new Date(getCurrentProject().startDate);
    let startMonthIndex = startDate.getMonth() + 1; // adjusting for the 0 base

    //calculate the totals - based on the 'payment frequency'  'payment month' & 'first month of accrual'
      //OK - for ANNUAL - we take the 12 months - sum them up, then place the value in the paymentMonth
      //OK - for QUARTERLY - we take the 3 months - sum them up, then place the value in the paymentMonth
      //OK - for SEMI-ANNUAL - we take the 6 months - sum them up, then place the value in the paymentMonth
      // if the start of the project is first instance February, and the firstMonthOfAccrual is January

      //then we place that value in the firstMonthOfAccrual field
    _.forEach(paymentCategories, function(paymentCategory, i) {

        let paymentFrequencyDto = prepayment.currentAssetDto.paymentFrequencyDtos.find(paymentFrequencyDto => paymentFrequencyDto.item === paymentCategory.overheadId);

        let paymentFrequency = paymentFrequencyDto.paymentFrequencyEnum;
        let firstMonthOfAccrual = paymentFrequencyDto.firstMonthOfAccrual; //for prepayments these are identical

      let periodLength; // Number of months between payments

        // Determine period length based on frequency
        if (paymentFrequency === 'ANNUAL') {
          periodLength = 12;
        } else if (paymentFrequency === 'SEMI_ANNUAL') {
          periodLength = 6;
        } else if (paymentFrequency === 'QUARTERLY') {
          periodLength = 3;
        } else {
          throw new Error('Invalid frequency. Must be ANNUAL, SEMI_ANNUAL, or QUARTERLY.');
        }

        //find the matching accrual row
        let matchingAccrualCategory = accrualCategories.find(accrualCategory => accrualCategory.overrideName === paymentCategory.overrideName);

        const paymentIndexes = calculatePaymentIndexes(paymentFrequency, startMonthIndex, firstMonthOfAccrual, 72);


        _.forEach(paymentCategory.currentAssetMonthDtos, function(prepaymentMonth, i) {

            //first lets get to the payment Month
            if (paymentIndexes.includes(prepaymentMonth.monthIndex)) {

              //we have found the first payment month (ie January)
              //now we sum up the values for the next 12/6/3 months and place it in that cell
              let count = 0; // Initialize counter

              let total = 0;

              while (count < periodLength) {

                if (matchingAccrualCategory.currentAssetMonthDtos[i + count] !== undefined) {
                  total = total + matchingAccrualCategory.currentAssetMonthDtos[i + count].value;
                }

                count++; // Increment the counter
              }

              prepaymentMonth.value = Math.abs(total);

            }

        });


      // ie Output: [1, 13, 25, 37, 49, 61] - if January Start Date and Annual
       // const paymentIndexes = calculatePaymentIndexes(paymentFrequency, startMonthIndex, firstMonthOfAccrual, 72);



    });

    // Now calculate the corkscrew
    _.forEach(openingCategory.currentAssetMonthDtos, function(openingMonth, i) {
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        // Set opening value to previous month's closing value
        openingCategory.currentAssetMonthDtos[i].value = closingCategory.currentAssetMonthDtos[i - 1].value || 0;

        // Sum over all 'accrualCategories' for month 'i'
        let totalAccruals = _.sumBy(accrualCategories, function(accrualCategory) {
          return accrualCategory.currentAssetMonthDtos[i].value || 0;
        });

        // Sum over all 'paymentCategories' for month 'i'
        let totalPayments = _.sumBy(paymentCategories, function(paymentCategory) {
          return paymentCategory.currentAssetMonthDtos[i].value || 0;
        });

        // Calculate closing value
        closingCategory.currentAssetMonthDtos[i].value =
          openingCategory.currentAssetMonthDtos[i].value +
          totalPayments +
          totalAccruals +
          adjustmentCategory.currentAssetMonthDtos[i].value;
      }
    });

  });

}


function calculatePaymentIndexes(frequency, startDateMonthIndex, firstPaymentMonthIndex, maxItems = 72) {
  const indexes = [];
  let periodLength; // Number of months between payments

  // Determine period length based on frequency
  if (frequency === 'ANNUAL') {
    periodLength = 12;
  } else if (frequency === 'SEMI_ANNUAL') {
    periodLength = 6;
  } else if (frequency === 'QUARTERLY') {
    periodLength = 3;
  } else {
    throw new Error('Invalid frequency. Must be ANNUAL, SEMI_ANNUAL, or QUARTERLY.');
  }

  // Calculate months until first payment
  let monthsUntilFirstPayment = (firstPaymentMonthIndex - startDateMonthIndex + 12) % 12 + 1;

  // Calculate the index of the first payment after the project starts
  let paymentIndex = monthsUntilFirstPayment - 1;

  // Generate payment indexes
  while (paymentIndex < maxItems) {
    indexes.push(paymentIndex); // Convert to 1-based index
    paymentIndex += periodLength;
  }

  return indexes;
}



export function calculateCurrentAssets(currentAssets, vatPackageAtom, pnl, releaseProfileAtom, productsAtom) {

  calculateCorkscrew(currentAssets, 'currentAsset');
  try {
    calculateInventoryModelling(currentAssets, pnl);
  } catch (e) {
    console.log('unable to calculate inventory modelling')
    console.log(e)
  }

  if (currentAssets.length > 0 && !_.isNil(vatPackageAtom)) {

    let vatPackage = vatPackageAtom;

    const currentAssetTradeDebtor = currentAssets
      .filter(currentAsset => currentAsset.currentAssetDto.assumption === "TRADE_DEBTORS")[0];

    calculateTradeDebtors(vatPackage, currentAssetTradeDebtor);
    calculatePercentageOfDebtors(currentAssets);
    calculatePercentageOfRevenue(vatPackage, currentAssets);
    calculatePercentageOfOverheads(vatPackage, currentAssets, pnl);
    calculateLoanAssets(currentAssets);
    calculateAccruals(currentAssets, releaseProfileAtom, productsAtom, pnl);
    calculatePrepayments(currentAssets, pnl);

  }
}


// export async function calculateCurrentAssets(currentAssets, vatPackageAtom, setCurrentAssets, pnl) {
//
//
//   //todo NOTE
//   //for the first 12 months (actuals, we AREN'T applying the calculations)
//   //as these are actual values
//   //we ONLY apply the calculations for the forecast values onwards.
//
//   calculateCorkscrew(currentAssets, 'currentAsset');
//   try {
//     calculateInventoryModelling(currentAssets, pnl);
//   } catch (e) {
//     console.log('unable to calculate inventory modelling')
//     console.log(e)
//   }
//
//   if (currentAssets.length > 0 && !_.isNil(vatPackageAtom)) {
//
//     let vatPackage = vatPackageAtom;
//
//     const currentAssetTradeDebtor = currentAssets
//       .filter(currentAsset => currentAsset.currentAssetDto.assumption === "TRADE_DEBTORS")[0];
//
//     calculateTradeDebtors(vatPackage, currentAssetTradeDebtor);
//     calculatePercentageOfDebtors(currentAssets);
//     calculatePercentageOfRevenue(vatPackage, currentAssets);
//     calculatePercentageOfOverheads(vatPackage, currentAssets, pnl);
//
//     setCurrentAssets([...currentAssets]);
//   }
//
// }


function calculatePercentageOfOverheads(vatPackage, currentAssets, pnl) {

  const directProductCosts = pnl.pnLCategoryDtoList
    .find(category => category.overrideName === "Direct product costs");

  const currentAssetPercentageOfOverheads = currentAssets
    .filter(currentAsset => currentAsset.currentAssetDto.assumption === "PERCENTAGE_OF_CERTAIN_OVERHEADS");

  // FOR each instance, we need to sum up all of the selected products (including VAT)
  // Then we simply times this by the percentage, like we do with PAYE or Percentage of creditors/debtors
  _.forEach(currentAssetPercentageOfOverheads, function(currentAssetPercentageOfOverhead) {

    let overheadsToSumUp = vatPackage.overheads
      .filter(overhead => currentAssetPercentageOfOverhead.currentAssetDto.overheads.includes(overhead.overheadDto.id));

    let salariesToSumUp = pnl.pnLCategoryDtoList
      .filter(category => category.headcountId !== null && currentAssetPercentageOfOverhead.currentAssetDto.salaries.includes(category.headcountId));

    _.forEach(currentAssetPercentageOfOverhead.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(currentAssetMonthDto, i) {

      let sum = 0;

      //Sum up all overheads
      _.forEach(overheadsToSumUp, function(overhead, c) {

        let valueInPnlCategory = overhead.overheadCategoryDtoList.find(category => category.name === "Value In P&L");
        if (!_.isNil(valueInPnlCategory)) {
          sum += isNanReturn0(valueInPnlCategory.overheadMonthDtoList[i].expenseIncludingVat);
        }

      });

      //Sum up Salaries and add to overheads sum
      _.forEach(salariesToSumUp, function(salary) {

        sum += isNanReturn0(salary.pnLMonthDtoList[i].value);

      });

      if (currentAssetPercentageOfOverhead.currentAssetDto.directProductCosts) {
        sum += directProductCosts.pnLMonthDtoList[i].value;
      }

      currentAssetMonthDto.value = sum;

      if (i < getCurrentProject().firstBalanceForecast) {

        currentAssetPercentageOfOverhead.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value =
          percentage(currentAssetPercentageOfOverhead.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value,
            currentAssetPercentageOfOverhead.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        currentAssetPercentageOfOverhead.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value =
          isNanReturn0(((currentAssetPercentageOfOverhead.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value / 100 ) *
            currentAssetPercentageOfOverhead.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value ));
      }

    })


  })

}

function calculatePercentageOfRevenue(vatPackage, currentAssets) {

  const currentAssetPercentageOfRevenues = currentAssets
    .filter(currentAsset => currentAsset.currentAssetDto.assumption === "PERCENTAGE_OF_CERTAIN_REVENUE");

  // FOR each instance, we need to sum up all of the selected products (including VAT)
  // Then we simply times this by the percentage, like we do with PAYE or Percentage of creditors/debtors
  _.forEach(currentAssetPercentageOfRevenues, function(currentAssetPercentageOfRevenue) {

    let productsToSumUp = vatPackage.products
        .filter(product => currentAssetPercentageOfRevenue.currentAssetDto.products.includes(product.productDto.id));

    _.forEach(currentAssetPercentageOfRevenue.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(currentAssetMonthDto, i) {


      let sum = 0;
      _.forEach(productsToSumUp, function(product, c) {

        let revenueCategory = product.productCategoryDtoList.find(category => category.name === "Revenue" || category.name === "Closing recurring revenue");
        if (!_.isNil(revenueCategory)) {
          sum += isNanReturn0(revenueCategory.productMonthDtoList[i].valueIncludingVat);
        }


      });

      currentAssetMonthDto.value = sum;

      if (i < getCurrentProject().firstBalanceForecast) {

        //set the Percentage of selected revenue
        currentAssetPercentageOfRevenue.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value =
          percentage(currentAssetPercentageOfRevenue.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value,
            currentAssetPercentageOfRevenue.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast -1) {

        //set the Percentage of selected revenue
        currentAssetPercentageOfRevenue.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value =
          isNanReturn0(((currentAssetPercentageOfRevenue.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value / 100 ) *
            currentAssetPercentageOfRevenue.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value ));
      }

    })



  })

}


function calculatePercentageOfDebtors(currentAssets) {

  const currentAssetsPercentageOfDebtors = currentAssets
    .filter(currentAsset => currentAsset.currentAssetDto.assumption === "PERCENTAGE_OF_DEBTORS");

  const currentAssetTradeCreditor = currentAssets
    .filter(currentAsset => currentAsset.currentAssetDto.assumption === "TRADE_DEBTORS")[0];

  let closingDebtors = currentAssetTradeCreditor.currentAssetCategoryDtos.find(category => category.name === 'Debtors in the balance sheet')

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  _.forEach(currentAssetsPercentageOfDebtors, function(currentAsset) {
    _.forEach(currentAsset.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(currentAssetMonthDto, i) {


      currentAsset.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value =
        isNanReturn0(closingDebtors.currentAssetMonthDtos[i].value);



      if (i < getCurrentProject().firstBalanceForecast) {

        //set the value of the PAYE value
        currentAsset.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value =
          percentage(currentAsset.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value,
            currentAsset.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        //set the value of the PAYE value
        currentAsset.currentAssetCategoryDtos[2].currentAssetMonthDtos[i].value =
          isNanReturn0(((currentAsset.currentAssetCategoryDtos[0].currentAssetMonthDtos[i].value / 100 ) *
            currentAsset.currentAssetCategoryDtos[1].currentAssetMonthDtos[i].value ));
      }
    });
  });


}

/**
 * For trade debtors:
 *
 *
 *
 * DATA FROM MODEL/ CALCULATION: Take the revenue of every product individually (from the revenue tab) and layer them in a grid (row after row). Add VAT to each revenue line.
 *
 * 1ST ASSUMPTION: In the next section, allow the user to add an assumption for each line for the number of days the invoice is paid after it is recorded as revenue. This is called “days receivable”.
 *
 * CALCULATION: Next you have to calculate the proportion of the days in the month that using the assumption in (2). For example, If the assumption is 30 and the month is June, the calculation is 100% of revenue.
 *
 * If the number is less than the days of the month it is a proportion of that month’s revenue (e.g 15 = 50% of June’s revenue).
 *
 * FOR MVP: if the calculation is >100%, still use the proportion of that month’s revenue e.g 45 = 150% of June’s revenue. Note – we will improve this in later versions of the model (v.2.0) but this is actually how most modelling is done. I have a proprietary calculation method that is more accurate.
 *
 * Once you know the output, this is called “calculated debtors”
 *
 * 2ND ASSUMPTION: There is often a difference between “calculated debtors” and closing debtors on balance sheet. This is known as “aged debtors”. This should be a simple corkscrew. The data for this doesn’t come from the balance sheet – the user has to input it directly. The middle part of the corkscrew should read “new aged debt” and “aged debt released”
 *
 * Closing debtors that feeds into the balance sheet is “calculated debtors + aged debtors”
 *
 * @param vatPackage
 * @param currentAssets
 */
function calculateTradeDebtors(vatPackage, currentAssetTradeDebtor) {

  // for each product in the vatPackage
  _.forEach(vatPackage.products, function(product) {

    // find the companion row in the trade debtor current asset
    const currentAssetTradeDebtorProductCategory = currentAssetTradeDebtor.currentAssetCategoryDtos
      .filter(currentAssetCategoryDto => currentAssetCategoryDto.productId === product.productDto.id && currentAssetCategoryDto.name === "Product Name")[0];

    let productRevenue = product.productCategoryDtoList
      .filter(productCategoryDto => productCategoryDto.name === "Revenue" || productCategoryDto.name === "Closing recurring revenue")[0];

    _.forEach(productRevenue.productMonthDtoList, function(productRevenueMonth, i) {

      // set the value of the product int he current asset (including the vat)
      if (currentAssetTradeDebtorProductCategory !== undefined) {
        currentAssetTradeDebtorProductCategory.currentAssetMonthDtos[i].value = isNanReturn0(productRevenueMonth.valueIncludingVat);
      }
    });

  });

  // ok, now we have the fixed rows, the formula is listed in the description

  const calculatedDebtors = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Calculated Debtors")[0];

  let calculatedDebtArr = [];

      // for each product work out the value based on the days receivable
      const productsAndTheirDaysReceivable = currentAssetTradeDebtor.currentAssetCategoryDtos
        .filter(currentAssetCategoryDto => currentAssetCategoryDto.productId !== null);

      _.forEach(productsAndTheirDaysReceivable, function(productAndTheirDaysReceivable, y) {

        if (productAndTheirDaysReceivable.name !== "Days Receivable") {


          _.forEach(productAndTheirDaysReceivable.currentAssetMonthDtos, function(currentAssetMonthDto, x) {

            // if the days is less than the number of days in this month, then its x 50%
            // if its the same number of days in the month, then its 100%
            // if it's great then the same number of days then, its relative percentage of the no. days in the month
            // ie 30 days in june, 45 in the days received, percentage is 150%

            // to get this month, we need the starting month of the project + monthIndex
            // then we can get the number of days in the month
            //project.startDate
            let projectStartDate = new Date(getCurrentProject().startDate);
            projectStartDate.setDate(1);

            projectStartDate.setMonth(projectStartDate.getMonth() + currentAssetMonthDto.monthIndex);

            const daysInMonth = getNumberOfDaysInMonth(projectStartDate.getMonth(), projectStartDate.getFullYear()); // Returns number of days for the month

            let daysReceivable = productsAndTheirDaysReceivable.find(category => category.productId === productsAndTheirDaysReceivable[y].productId && category.name === 'Days Receivable').currentAssetMonthDtos[x].value;

            let percentage = isWhatPercentOf(daysReceivable, daysInMonth);

            let addPercentageVal = addPercentage(currentAssetMonthDto.value, percentage);

            //console.log('daysInMonth', daysInMonth, 'daysReceivable', daysReceivable, 'percentage', percentage, 'addPercentageVal', addPercentageVal)

            //console.log("Calculated Debtors:", calculatedDebtors.currentAssetMonthDtos[x].value, "days in month:", daysInMonth, "days receivable:", daysReceivable, "percentage", percentage, "addPercentage:", addPercentage(currentAssetMonthDto.value, percentage), "calculatedDebtors.currentAssetMonthDtos[i].value", calculatedDebtors.currentAssetMonthDtos[i].value, "x is: ", x)

            //calculatedDebtors.currentAssetMonthDtos[x].value += Math.round(addPercentageVal);
            if (isNaN(calculatedDebtArr[x])) {
              calculatedDebtArr[x] = 0;
            }
            calculatedDebtArr[x] += addPercentageVal;
          });

        }

      });

  // Then we'll do the corkscrew for the aged debtors
  _.forEach(calculatedDebtors.currentAssetMonthDtos, function(currentAssetMonthDto, i) {
    currentAssetMonthDto.value = calculatedDebtArr[i];
  });


  // NOW FOR THE CORKSCREW - Aged Debtors

  calculateTradeDebtorCorkscrew(currentAssetTradeDebtor);

}

function calculateTradeDebtorCorkscrew(currentAssetTradeDebtor) {
  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW

  const calculatedDebtors = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Calculated Debtors")[0];

  const agedDebtorsOpening = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Opening Aged Debtors")[0];

  const newAgedDebt = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "New Aged Debt")[0];

  const agedDebtReleased = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Aged debt released")[0];

  const agedDebtClosing = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Closing Aged Debtors")[0];

  const closingDebtors = currentAssetTradeDebtor.currentAssetCategoryDtos
    .filter(currentAssetCategoryDto => currentAssetCategoryDto.name === "Debtors in the balance sheet")[0];

  _.forEach(currentAssetTradeDebtor.currentAssetCategoryDtos[0].currentAssetMonthDtos, function(currentAssetMonthDto, i) {


    if (i <= getCurrentProject().firstBalanceForecast) {

      agedDebtClosing.currentAssetMonthDtos[i].value =

        agedDebtorsOpening.currentAssetMonthDtos[i].value +
        newAgedDebt.currentAssetMonthDtos[i].value +
        agedDebtReleased.currentAssetMonthDtos[i].value;
    }


    if (i > getCurrentProject().firstBalanceForecast - 1) {

      agedDebtorsOpening.currentAssetMonthDtos[i].value = agedDebtClosing.currentAssetMonthDtos[i - 1].value;

      agedDebtClosing.currentAssetMonthDtos[i].value =
        agedDebtorsOpening.currentAssetMonthDtos[i].value +
        newAgedDebt.currentAssetMonthDtos[i].value +
        agedDebtReleased.currentAssetMonthDtos[i].value;

      closingDebtors.currentAssetMonthDtos[i].value
        = isNanReturn0(calculatedDebtors.currentAssetMonthDtos[i].value + agedDebtClosing.currentAssetMonthDtos[i].value);
    }

  });


}

function isNanReturn0(value) {
  if (isNaN(value)) {
    return 0;
  } else {
    return Math.round(value);
  }
}

function addPercentage(value, percentageToAdd) {

  if (percentageToAdd === 0) {
    return 0;
  }

  let valueWithPercentageAdded = ((value / 100) * percentageToAdd);

  if (isNaN(valueWithPercentageAdded)) {
    return 0;
  } else {
    return valueWithPercentageAdded;
  }
}

function isWhatPercentOf(x, y) {
  if (x === y) {
    return 100;
  }
  return (x / y) * 100;
}


