import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";

import { generateXAnnualAxisHeadersAnnual } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
const Card = styled(MuiCard)(spacing);

const tableStyling = {
  minWidth: 90,
  fontWeight: "bolder",
  color: "white",
};

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};


function PnLAnnualInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const zoomAtom = useRecoilValue(zoomState);
  const [itemsToIgnore, setItemsToIgnore] = useState([]);

  const overheadsAtom = useRecoilValue(overheadsState);
  const headCountAtom = useRecoilValue(headcountsState);

  const {
    tableRef,
    pnLItem,
    pnl,
    refreshPnLData,
    setPnl,
    setEditData,
    editData
  } = props;

  useEffect(() => {

    let overheadsToIgnore = isEmptyDefaultGroup(overheadsAtom);
    let headcountsToIgnore = isEmptyHeadcountsDefaultGroup(headCountAtom);
    let itemsToIgnore = overheadsToIgnore.concat(headcountsToIgnore);
    setItemsToIgnore(itemsToIgnore);

    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.overheadDto.name.endsWith("(Default Overhead Group)") && item.overheadDto.overheads.length === 0).map(item => item.overheadDto.name);
    } else {
      return [];
    }

  };

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyHeadcountsDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.headCountDto.name.endsWith("(Default Headcount Group)") && item.headCountDto.headcounts.length === 0).map(item => item.headCountDto.name);
    } else {
      return [];
    }

  };
  
  return (
    <React.Fragment key={key}>

      <Card
        mb={6}
        sx={{
          ...tableWidth,
          overflowX: "auto",
          overflow: "scroll",
          paddingTop: 0,
        }}
        id={pnLItem.pnLDto.name.replaceAll(" ", "-") + "-table"}
      >
        <CardContent  sx={{ paddingTop: 5, zoom: zoomAtom }}>
          <Table ref={tableRef}>
            {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
            <TableHead >
              <TableRow
                key={pnLItem.pnLDto.name + "-ROW"}
                id={pnLItem.pnLDto.name + "-ROW"}
              >
                <TableCell
                  key={pnLItem.pnLDto.name + "-BASE"}
                  id={pnLItem.pnLDto.name + "-BASE"}
                  style={{ position: "sticky", left: 0 }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    width: 200,
                    backgroundColor: "#F7F9FC",
                  }}
                >

                </TableCell>
                {XaxisHeaders.map((yHeader, index) => (
                  <TableCell
                    key={
                      pnLItem.pnLDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    id={
                      pnLItem.pnLDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    variant={"head"}
                    align="right"
                    sx={{
                      ...tableStyling,
                      backgroundColor: yHeader.backgroundColor,
                    }}
                  >
                    {yHeader.type}
                    <br />
                    {yHeader.financialMonth}
                    <br />
                    {yHeader.financialYear}
                    <br />
                    {yHeader.financialYearIndex}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {pnLItem.pnLCategoryDtoList.filter(category => !itemsToIgnore.includes(category.overrideName)).map((row) => (
                <TableRow key={row.id}
                          id={row.id}
                          sx={{borderStyle: row.totalField === true && !["Net Profit Margin", "EBITDA Margin", "Gross Profit Margin"].includes(row.overrideName) ? "double" : "none", }}
                          onClick={() => handleClick(row.id)}>
                  <TableCell
                    component="th"
                    scope="row"
                    variant={"body"}
                    sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#F7F9FC",
                      zIndex: 1,
                      
                    }}
                  >
                    {row.overrideName === "" ? row.name : row.overrideName}

                  </TableCell>

                  {row.pnLMonthDtoList.map((month) => (
                    <PowdrCell
                      key={month.id + "-CELL"}
                      cellData={month}
                      ownerId={row.id}
                      editTableEnabled={editData}
                      items={pnl}
                      refreshItemData={refreshPnLData}
                      setItems={setPnl}
                    ></PowdrCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default PnLAnnualInput;
