import React, { useRef } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { CustomColorIconButton } from "../../../SharedComponents/utils/CustomIconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Edit from "@mui/icons-material/Edit";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Delete } from "@mui/icons-material";
import EditOverhead from "../../../AssumptionsGroup/Overheads/components/EditOverhead";
import EditHeadCount from "../../../AssumptionsGroup/HeadCount/components/EditHeadCount";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

function PnLMenu(props) {

  const updatePowdrModelGlobalRef = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditHeadCount, setOpenEditHeadCount] = React.useState(false);
  const [overheadToEdit, setOverheadToEdit] = React.useState(null);
  const [headCountToEdit, setHeadCountToEdit] = React.useState(null);
  const [topVal, setTopVal] = React.useState(500);

  const open = Boolean(anchorEl);
  const { name,
    pnlItem,
    moveOverhead,
    refreshPnLData,
    projectId,
    deletePnlCategory,
    products,
    headcounts
  } = props;
  const handleClick = (event) => {
    setTopVal(event.clientY + 15)
    setAnchorEl(event.currentTarget);
  };

  function getOverheadById (id) {
    var config = {
      method: "get",
      url: "overheads/" + projectId + "/item/" + id,
    };

    axios(config)
      .then(function (response) {
        // get overhead
        setOverheadToEdit(response.data);
        setOpenEdit(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getHeadCountById (id) {
    var config = {
      method: "get",
      url: "headcounts/" + projectId + "/item/" + id,
    };

    axios(config)
      .then(function (response) {
        // get overhead
        setHeadCountToEdit(response.data);
        setOpenEditHeadCount(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const handleClose = (event) => {
    setAnchorEl(null);

    if (event.target.outerText === 'Edit') {
      //retrieve the overhead/headcount & set it
      if (pnlItem.overheadId != null) {
        getOverheadById(pnlItem.overheadId);
      } else if (pnlItem.headcountId != null) {
        getHeadCountById(pnlItem.headcountId)
      }
    }

    if (event.target.outerText === 'Move Up') {
      moveOverhead(pnlItem.overheadId, pnlItem.headcountId, "UP");
    }

    if (event.target.outerText === 'Move Down') {
      moveOverhead(pnlItem.overheadId, pnlItem.headcountId, "DOWN");
    }

    if (event.target.outerText === 'Delete') {
      deletePnlCategory(pnlItem);
    }

  };


  return (
    <React.Fragment>

      <Tooltip title="" sx={{textAlign: "left"}} onClick={handleClick}>
        <CustomColorIconButton

          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {name} <MoreVertIcon fontSize="small"></MoreVertIcon>
        </CustomColorIconButton>
      </Tooltip>


      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: topVal, left: 475 }}
        id="account-menu"
        open={open}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem value="edit" disabled={pnlItem.overrideName === "Exceptionals"}>
          <ListItemIcon value="edit" >
            <Edit fontSize="small" >
              Edit
            </Edit>
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem value="moveup" disabled={pnlItem.overrideName === "Exceptionals"}>
          <ListItemIcon value="delete">
            <ArrowDropUp fontSize="small" >
              Move Up
            </ArrowDropUp>
          </ListItemIcon>
          Move Up
        </MenuItem>
        <MenuItem value="movedown" disabled={pnlItem.overrideName === "Exceptionals"}>
          <ListItemIcon value="delete">
            <ArrowDropDown fontSize="small" >
              Move Down
            </ArrowDropDown>
          </ListItemIcon>
          Move Down
        </MenuItem>
        <MenuItem value="delete" disabled={pnlItem.canDelete !== undefined && pnlItem.canDelete === false}>
          <ListItemIcon value="delete">
            <Delete fontSize="small" >
              Delete
            </Delete>
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      {
        openEdit && (
          <EditOverhead overheadNames={[]}
                        loadOverheadData={refreshPnLData}
                        overhead={overheadToEdit}
                        open={openEdit}
                        setOpen={setOpenEdit}
                        products={products}
                        headcounts={headcounts}
                        updatePowdrModelGlobalRef={updatePowdrModelGlobalRef}>
          </EditOverhead>
        )}


      {
        openEditHeadCount && (
          <EditHeadCount headCountNames={[]}
                         loadData={refreshPnLData}
                         headcount={headCountToEdit.headCountDto}
                         open={openEditHeadCount}
                         setOpen={setOpenEditHeadCount}
                         categories={headCountToEdit.headCountCategoryDtos}
                         updatePowdrModelGlobalRef={updatePowdrModelGlobalRef}>
          </EditHeadCount>
        )}

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

    </React.Fragment>
  );
}

export default PnLMenu;