import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";


import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography, Button
} from "@mui/material";
import { spacing } from "@mui/system";
import OverheadsChart from "./components/OverheadsChart";
import EbitdaChart from "./components/EbitdaChart";
import ClosingCashChart from "./components/ClosingCash";
import getProjectId, { getCurrentProject } from "../../SharedComponents/ProjectServices";
import axios from "axios";
import RevenueAndEbitda from "./components/RevenueAndEbitda";
import RevenueChart from "./components/RevenueChart";
import NetCashFlowChart from "./components/NetCashFlowChart";
import { getZoom } from "../../SharedComponents/utils/Utils";
import KpiInput from "./components/KpiInput";
import ExportBar from "../../../presentation/PowdrLanding/ExportBar";
import ExportBarFooter from "../../../presentation/PowdrLanding/ExportBarFooter";
import { exportScreen } from "../../SharedComponents/utils/ExportUtils";
import LoadingBar from "../../SharedComponents/LoadingBar";
import useProject from "../../../../hooks/useProject";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function Dashboard() {
  const [cashflow, setCashflow] = useState();
  const [cashflowAnnual, setCashflowAnnual] = useState();
  const [pnl, setPnl] = useState();
  const [pnlAnnual, setPnlAnnual] = useState();
  const [kpi, setKpi] = useState();
  const [zoomScale, setZoomScale] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const componentRef = useRef();
  const { project } = useProject();

  async function loadCashflow() {
    setZoomScale(getZoom)
    let project = await getProjectId();

    axios.get("dashboard/" + project.id)
      .then(function(response) {
        setCashflow(response.data.cashflowData);
        setCashflowAnnual(response.data.allCashflowAnnualData)
        setPnlAnnual(response.data.allPnlAnnualData)
        setKpi(response.data.allKpiData)
        setPnl(response.data.pnl)
        setLoaded(true)
      });

  }



  useEffect(() => {
    loadCashflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Dashboard
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      <LoadingBar loaded={loaded} assumptionName={"Dashboard"}/>

      {!!cashflow &&
        <Button type="submit" variant="contained" onClick={() => exportScreen('Dashboard', componentRef)}
                sx={{ marginBottom: 5 }}>
          Export Dashboard
        </Button>
      }

      <div ref={componentRef}>

      <ExportBar name={getCurrentProject().name} ></ExportBar>

      {!!kpi && (
          <KpiInput
            key={"kpi"}
            pnLItem={kpi}
            pnl={kpi}
            setPnl={setPnl}
            pageZoom={zoomScale}
          ></KpiInput>
        )}

        {!!cashflow && ['SINGLE_ENTITY', 'PNL_DIVISIONAL'].includes(project.projectType) &&
          <Grid container spacing={6}>



            <Grid item xs={12}>
              <RevenueAndEbitda pnl={pnl} />
            </Grid>


            <Grid item xs={12} md={6}>
              <RevenueChart pnlAnnual={pnlAnnual} />
            </Grid>

            <Grid item xs={12} md={6}>
              <EbitdaChart pnlAnnual={pnlAnnual} />
            </Grid>
            <Grid item xs={12} md={6}>
              <NetCashFlowChart cashflow={cashflowAnnual} />
            </Grid>

            <Grid item xs={12} md={6}>
              <OverheadsChart pnlAnnual={pnlAnnual} />
            </Grid>

            <Grid item xs={12}>
              <ClosingCashChart cashflow={cashflow} />
            </Grid>

          </Grid>
        }

        {!!cashflow && project.projectType === 'PNL' &&
          <Grid container spacing={6}>

            <Grid item xs={12}>
              <RevenueAndEbitda pnl={pnl} />
            </Grid>


            <Grid item xs={12} md={6}>
              <RevenueChart pnlAnnual={pnlAnnual} />
            </Grid>

            <Grid item xs={12} md={6}>
              <EbitdaChart pnlAnnual={pnlAnnual} />
            </Grid>

            <Grid item xs={12} md={6}>
              <OverheadsChart pnlAnnual={pnlAnnual} />
            </Grid>

          </Grid>
        }

      <ExportBarFooter></ExportBarFooter>

      </div>
    </React.Fragment>
  );
}

export default Dashboard;
